<template>
  <div>
    <VueSlickCarousel
      v-bind="settings"
      class="slider-activation rn-slick-dot dot-light"
    >
      <div
        class="
          slide slide-style-2
          slider-box-content
          without-overlay
          d-flex
          align-center
          bg_image
        "
        data-black-overlay="1"
        v-for="(slider, i) in sliderContent"
        :key="i"
        :style="{
          backgroundImage: 'url(' + slider.src + ')',
          position: 'relative',
        }"
      >
        <v-row
          style="
            position: absolute !important;
            bottom: 20vh !important;
            left: 6vw !important;
          "
          class="d-none d-sm-block getMargin"
        >
          <!-- <v-row> -->
          <v-col
            xl="9"
            lg="9"
            md="9"
            sm="12"
            xs="12"
            cols="12"
            class="inner"
            :class="slider.text_position"
          >
            <h2 class="description">{{ slider.title }}</h2>
            <p class="description">
              {{ slider.desc }}
            </p>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="8"
            sm="12"
            xs="12"
            cols="12"
            class="inner"
            :class="slider.text_position"
          >
            <v-row>
              <v-col
                xl="3"
                lg="3"
                md="3"
                sm="3"
                cols="3"
                class="pt_sm--0 pt--30"
              >
                <a>
                  <img
                    :style="
                      $store.state.selectedTab === 'white'
                        ? { opacity: 1 }
                        : { opacity: 0.5 }
                    "
                    @click="$store.state.selectedTab = 'white'"
                    class="mb--10 imageStyle"
                    src="../../assets/images/home/white.png"
                    alt="Corporate Images"
                  />
                </a>
              </v-col>
              <v-col
                xl="3"
                lg="3"
                md="3"
                sm="3"
                cols="3"
                class="pt_sm--0 pt--30"
              >
                <a
                  ><img
                    :style="
                      $store.state.selectedTab === 'blue'
                        ? { opacity: 1 }
                        : { opacity: 0.5 }
                    "
                    @click="$store.state.selectedTab = 'blue'"
                    class="mb--50 imageStyle"
                    src="../../assets/images/home/blue.png"
                    alt="Corporate Images"
                /></a>
              </v-col>
              <v-col
                xl="3"
                lg="3"
                md="3"
                sm="3"
                cols="3"
                class="pt_sm--0 pt--30"
              >
                <a
                  ><img
                    :style="
                      $store.state.selectedTab === 'silver'
                        ? { opacity: 1 }
                        : { opacity: 0.5 }
                    "
                    @click="$store.state.selectedTab = 'silver'"
                    class="mb--50 imageStyle"
                    src="../../assets/images/home/silver.png"
                    alt="Corporate Images"
                /></a>
              </v-col>
              <v-col
                xl="3"
                lg="3"
                md="3"
                sm="3"
                cols="3"
                class="pt_sm--0 pt--30"
              >
                <a
                  ><img
                    :style="
                      $store.state.selectedTab === 'gold'
                        ? { opacity: 1 }
                        : { opacity: 0.5 }
                    "
                    @click="$store.state.selectedTab = 'gold'"
                    class="mb--50"
                    src="../../assets/images/home/gold.png"
                    alt="Corporate Images imageStyle"
                /></a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="d-block d-sm-none">
          <!-- <v-row> -->
          <v-col
            xl="9"
            lg="9"
            md="9"
            sm="12"
            xs="12"
            cols="12"
            class="inner getMarginSmall"
            :class="slider.text_position"
          >
            <v-row>
              <v-col cols="8">
                <h2 class="description">{{ slider.title }}</h2>
              </v-col>
              <v-col cols="8">
                <p class="description">
                  {{ slider.desc }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <div>
            <v-col
              xl="6"
              lg="6"
              md="8"
              sm="12"
              xs="12"
              cols="12"
              class="inner getMarginSmallSec"
              :class="slider.text_position"
            >
              <v-row>
                <!-- <v-col sm="1" cols="1"></v-col> -->
                <v-col sm="6" cols="6" class="pt_sm--0 pt--30">
                  <a>
                    <img
                      :style="
                        $store.state.selectedTab === 'white'
                          ? { opacity: 1 }
                          : { opacity: 0.5 }
                      "
                      @click="$store.state.selectedTab = 'white'"
                      class="mb--10 imageStyle"
                      src="../../assets/images/home/white.png"
                      alt="Corporate Images"
                    />
                  </a>
                </v-col>
                <v-col sm="6" cols="6">
                  <a
                    ><img
                      :style="
                        $store.state.selectedTab === 'blue'
                          ? { opacity: 1 }
                          : { opacity: 0.5 }
                      "
                      @click="$store.state.selectedTab = 'blue'"
                      class="mb--10 imageStyle"
                      src="../../assets/images/home/blue.png"
                      alt="Corporate Images"
                  /></a>
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col sm="1" cols="1"></v-col> -->
                <v-col sm="6" cols="6">
                  <a
                    ><img
                      :style="
                        $store.state.selectedTab === 'silver'
                          ? { opacity: 1 }
                          : { opacity: 0.5 }
                      "
                      @click="$store.state.selectedTab = 'silver'"
                      class="mb--10 imageStyle"
                      src="../../assets/images/home/silver.png"
                      alt="Corporate Images"
                  /></a>
                </v-col>
                <v-col sm="6" cols="6">
                  <a
                    ><img
                      :style="
                        $store.state.selectedTab === 'gold'
                          ? { opacity: 1 }
                          : { opacity: 0.5 }
                      "
                      @click="$store.state.selectedTab = 'gold'"
                      class="mb--50"
                      src="../../assets/images/home/gold.png"
                      alt="Corporate Images imageStyle"
                  /></a>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-row>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import Login from "@/views/Login";
import Register from "@/views/Register";
import axios from "axios";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: { VueSlickCarousel, Login, Register },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["token"]),
  },
  async created() {
    await this.getUser();
  },
  methods: {
    ...mapActions(["getUser"]),
    ...mapActions(["getToken"]),
  },
  data() {
    return {
      sliderContent: [
        {
          text_position: "text-left",
          src: require("../../assets/images/bg/bg-image-10.jpg"),
          title: "Βαθμίδες και προνόμια",
          desc: `Επιλέξτε και δείτε τα προνόμια της κάθε κάρτας`,
        },
      ],
      settings: {
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        margin: 20,
      },
    };
  },
};
</script>

<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
// .v-btn {
//   text-transform: lowercase;
// }

.theme--light.v-image {
  float: right;
}
.getMargin {
  margin-bottom: -120px;
  position: absolute !important;
  bottom: 20vh;
  left: 6vw;
}
.getMarginSmall {
  /* margin-bottom: -140px; */
  position: absolute !important;
  bottom: 10vh;
  left: 6vw;
}
.getMarginSmallSec {
  /* margin-bottom: -140px; */
  position: absolute !important;
  bottom: -35vh;
  left: 4vw;
}
.alignCenter {
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 8px !important;
  font-weight: 600;
  z-index: 999 !important;
  // border: 2px dashed #f69c55;
}

.imageStyle {
  max-width: 150px;
}

[data-black-overlay]::before {
  background: linear-gradient(to right, red 34%, #5b5b5b 65%);
}
</style>
<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
// .v-btn {
//   text-transform: lowercase;
// }
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 4px;
  padding-top: 0px;
  padding-right: 4px;
  padding-bottom: 0px;
  padding-left: 4px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.v-icon.v-icon {
  font-size: 16px;
}

.theme--light.v-image {
  float: right;
}

.alignCenter {
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 8px !important;
  font-weight: bold;
  // border: 2px dashed #f69c55;
}
</style>
<style scoped>
.slide.slide-style-2 .inner p.description {
  font-size: 30px;
  text-align: left !important;
}
h2.description {
  font-size: 40px !important;
  font-family: PFBagueSansPro-Bold !important;
  text-align: left !important;
}
p.description {
  font-size: 35px !important;
  font-family: PFBagueSansPro-Bold !important;
  text-align: left !important;
}
@media only screen and (max-width: 499px) {
  h2.description {
    font-size: 21px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  p.description {
    font-size: 14px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  .getMargin {
    /* margin-bottom: -140px; */
    position: absolute !important;
    bottom: 10vh;
    left: 6vw;
  }
  .v-application ul,
  .v-application ol,
  body ul,
  body ol {
    font-size: 16px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 593px) {
  h2.description {
    font-size: 30px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  p.description {
    font-size: 14px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  .getMargin {
    margin-bottom: -140px;
    position: absolute !important;
    bottom: 20vh;
    left: 6vw;
  }
  .v-application ul,
  .v-application ol,
  body ul,
  body ol {
    font-size: 16px;
  }
}
@media only screen and (min-width: 594px) and (max-width: 1199px) {
  h2.description {
    font-size: 30px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  p.description {
    font-size: 25px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  .getMargin {
    margin-bottom: -140px;
    position: absolute !important;
    bottom: 25vh;
    left: 6vw;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1263px) {
  h2.description {
    font-size: 32px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  p.description {
    font-size: 30px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  .getMargin {
    margin-bottom: -130px;
    position: absolute !important;
    bottom: 25vh;
    left: 6vw;
  }
}
@media only screen and (min-width: 1264px) and (max-width: 1450px) {
  h2.description {
    font-size: 40px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  p.description {
    font-size: 32px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  .getMargin {
    margin-bottom: -130px;
    position: absolute !important;
    bottom: 25vh;
    left: 6vw;
  }
}
.slide.slide-style-2 .inner {
  padding-top: 5px;
}
.slide.slide-style-2.slider-box-content {
  height: 500px !important;
}
@media only screen and (max-width: 599px) {
  .slide.slide-style-2.slider-box-content {
    height: 200px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 801px) {
  .slide.slide-style-2.slider-box-content {
    height: 350px !important;
  }
}
</style>
<style scoped>
.row {
  margin-right: 0px;
  margin-left: -15px;
}
.slide.slide-style-2 {
  padding-top: 230px;
  padding-bottom: 284px;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
}
</style>
