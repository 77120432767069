<template>
  <v-container>
    <!-- <v-row align="center" class="row--35"> -->
    <v-row class="row--35">
      <v-col lg="9" md="9" cols="12">
        <div class="about-inner inner">
          <!-- <div class="section-title"> -->
          <div class="section-title">
            <h3 class="heading-title colorBlue" style="font-size: 24px">
              Health_e Bonus Blue
            </h3>
          </div>
          <v-row class="mt--40 mt_sm--20">
            <v-col cols="12">
              <h3 class="heading-title colorBlue">ΕΠΙΣΚEΨΕΙΣ</h3>
              <p
                style="
                  font-size: 24px;
                  margin-top: -10px;
                  margin-bottom: 5px;
                  line-height: 1.2;
                "
                class="regularStyle"
              >
                Προγραμματισμένες επισκέψεις στα εξωτερικά ιατρεία του ομίλου σε
                επιμελητές ιατρούς <span class="colorBlue"> • 20 €</span>
              </p>
            </v-col>
          </v-row>
          <v-row class="mt_sm--10">
            <v-col cols="12">
              <div class="about-us-list">
                <v-simple-table>
                  <template v-slot:default>
                    <thead style="background-color: rgb(195 220 234)">
                      <tr>
                        <th class="text-left noBorderLeft noBorderTop">
                          ΕΙΔΙΚΟΤΗΤΕΣ ΙΑΤΡΩΝ
                        </th>
                        <th class="text-center noBorderTop">ΥΓΕΙΑ</th>
                        <th class="text-center noBorderTop">
                          ΜΕΤROPOLITAN <br />
                          HOSPITAL
                        </th>
                        <th class="text-center noBorderTop">MHTEΡΑ</th>
                        <th class="text-center noBorderTop">
                          ΜΕΤROPOLITAN <br />
                          GENERAL
                        </th>
                        <th class="text-center noBorderTop noBorderRight">
                          CRETA <br />
                          INTERCLINIC
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,i) in blue"
                        :key="item.id"
                        :class="i%2 ? 'colorBlueBackground' : ''"
                      >
                        <td
                          :id="item.id > 20 ? 'noBorderBottom' : ''"
                          class="noBorderLeft"
                        >
                          {{ item.name }}
                        </td>
                        <td :id="item.id > 20 ? 'noBorderBottom' : ''">
                          <center>
                            <v-icon v-if="item.ugeia"> fas fa-check </v-icon>
                          </center>
                        </td>
                        <td :id="item.id > 20 ? 'noBorderBottom' : ''">
                          <center>
                            <v-icon v-if="item.metropolitanHospital">
                              fas fa-check
                            </v-icon>
                          </center>
                        </td>
                        <td :id="item.id > 20 ? 'noBorderBottom' : ''">
                          <center>
                            <v-icon v-if="item.mitera"> fas fa-check </v-icon>
                          </center>
                        </td>
                        <td :id="item.id > 20 ? 'noBorderBottom' : ''">
                          <center>
                            <v-icon v-if="item.metropolitanGeneral">
                              fas fa-check
                            </v-icon>
                          </center>
                        </td>
                        <td
                          :id="item.id > 20 ? 'noBorderBottom' : ''"
                          class="noBorderRight"
                        >
                          <center>
                            <v-icon v-if="item.cretaInterClinic">
                              fas fa-check
                            </v-icon>
                          </center>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt--10 mt_sm--10">
            <v-col>
              <v-img
                max-height="60"
                max-width="60"
                class="mb--20"
                style="
                  position: absolute;
                  margin-top: -90px;
                  margin-left: -63px;
                "
                src="../../assets/images/pronomia/icon_kids1.png"
              ></v-img>
            </v-col>
          </v-row>
          <v-row class="mt--10 mt_sm--30">
            <v-col cols="12">
              <p
                style="font-size: 24px; margin-top: -10px; margin-bottom: 5px"
                class="regularStyle"
              >
                Επιπλέον ειδικότητες με μειωμένη χρέωση
                <span class="colorBlue"> • 30 €</span>
              </p>
            </v-col>
          </v-row>
          <v-row class="mt_sm--10">
            <v-col cols="12">
              <div class="about-us-list">
                <v-simple-table>
                  <template v-slot:default>
                    <thead style="background-color: rgb(195 220 234)">
                      <tr>
                        <th class="text-left noBorderLeft noBorderTop">
                          ΕΙΔΙΚΟΤΗΤΕΣ ΙΑΤΡΩΝ
                        </th>
                        <th class="text-center noBorderTop">ΥΓΕΙΑ</th>
                        <th class="text-center noBorderTop">
                          ΜΕΤROPOLITAN <br />
                          HOSPITAL
                        </th>
                        <th class="text-center noBorderTop">MHTEΡΑ</th>
                        <th class="text-center noBorderTop">
                          ΜΕΤROPOLITAN <br />
                          GENERAL
                        </th>
                        <th class="text-center noBorderTop noBorderRight">
                          CRETA <br />
                          INTERCLINIC
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in extraBlue" :key="item.id"
                        :class="i%2 ? 'colorBlueBackground' : ''"
                      >
                        <td
                          :id="item.id > 7 ? 'noBorderBottom' : ''"
                          class="noBorderLeft"
                        >
                          {{ item.name }}
                        </td>
                        <td :id="item.id > 7 ? 'noBorderBottom' : ''">
                          <center>
                            <v-icon v-if="item.ugeia"> fas fa-check </v-icon>
                          </center>
                        </td>
                        <td :id="item.id > 7 ? 'noBorderBottom' : ''">
                          <center>
                            <v-icon v-if="item.metropolitanHospital">
                              fas fa-check
                            </v-icon>
                          </center>
                        </td>
                        <td :id="item.id > 7 ? 'noBorderBottom' : ''">
                          <center>
                            <v-icon v-if="item.mitera"> fas fa-check </v-icon>
                          </center>
                        </td>
                        <td :id="item.id > 7 ? 'noBorderBottom' : ''">
                          <center>
                            <v-icon v-if="item.metropolitanGeneral">
                              fas fa-check
                            </v-icon>
                          </center>
                        </td>
                        <td
                          :id="item.id > 7 ? 'noBorderBottom' : ''"
                          class="noBorderRight"
                        >
                          <center>
                            <v-icon v-if="item.cretaInterClinic">
                              fas fa-check
                            </v-icon>
                          </center>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
            <v-row class="mt--20 mt_sm--30">
            <v-col cols="12">
              <p
                style="font-size: 24px; margin-top: -10px; margin-bottom: 5px"
                class="regularStyle"
              >
                Έξτρα εκπτώσεις
              </p>
            </v-col>
          </v-row>
          <v-row class="mt--10">
            <v-col lg="6" md="6" sm="12" cols="12">
              <div class="d-flex">
                <div class="p-6">
                  <p style="font-size: 18px">
                    <span style="font-size: 20px" class="colorBlue"> 15%</span>
                  </p>
                </div>
                <div class="p-6">
                  <p
                    style="
                      font-size: 18px;
                      margin-left: 10px;
                      padding-bottom: 35px;
                    "
                  >
                    στις φυσικοθεραπείες
                  </p>
                </div>
              </div>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12">
              <div class="d-flex">
                <div class="p-6">
                  <p style="font-size: 18px">
                    <span style="font-size: 20px" class="colorBlue"> 40%</span>
                  </p>
                </div>
                <div class="p-6">
                  <p style="font-size: 18px; margin-left: 10px">
                    στις οφθαλμολογικές εξετάσεις (βυθοσκόπηση, <br />
                    οπτικά πεδία, οπτική οξύτητα)
                  </p>
                </div>
              </div>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12">
              <div class="d-flex">
                <div class="p-6">
                  <p style="font-size: 18px">
                    <span style="font-size: 20px" class="colorBlue"> 10%</span>
                  </p>
                </div>
                <div class="p-6">
                  <p style="font-size: 18px; margin-left: 10px">
                    στις οδοντιατρικές υπηρεσίες <br />
                    (Υγεία και Metropolitan Hospital)
                  </p>
                </div>
              </div>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12" class="mt--5">
              <div class="d-flex">
                <div class="p-6">
                  <p style="font-size: 18px">
                    <span style="font-size: 20px" class="colorBlue"> 20%</span>
                  </p>
                </div>
                <div class="p-6">
                  <p style="font-size: 18px; margin-left: 10px">
                    έκπτωση στις λοιπές παιδιατρικές ειδικότητες (Παίδων <br />Μητέρα και
                    Μetropolitan Ηospital)
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col cols="12">
              <h3 class="heading-title colorBlue">ΕΠΕΙΓΟΝΤΑ ΠΕΡΙΣΤΑΤΙΚΑ</h3>
              <p
                style="
                  font-size: 24px;
                  margin-top: -10px;
                  margin-bottom: 5px;
                  line-height: 1.2;
                "
                class="regularStyle"
              >
                Επείγοντα περιστατικά σε εφημερεύοντες ιατρούς των εξωτερικών
                ιατρείων όλο το 24ωρο <span class="colorBlue"> • 20 €</span>
              </p>
            </v-col>
          </v-row>
          <v-row class="mt_sm--10">
            <v-col lg="12" md="12" sm="12" cols="12">
              <div class="about-us-list">
                <v-simple-table>
                  <template v-slot:default>
                    <thead style="background-color: rgb(195 220 234)">
                      <tr>
                        <th class="text-left noBorderLeft noBorderTop">
                          ΕΙΔΙΚΟΤΗΤΕΣ ΙΑΤΡΩΝ
                        </th>
                        <th class="text-center noBorderTop">ΥΓΕΙΑ</th>
                        <th class="text-center noBorderTop">
                          ΜΕΤROPOLITAN <br />
                          HOSPITAL
                        </th>
                        <th class="text-center noBorderTop">MHTEΡΑ</th>
                        <th class="text-center noBorderTop">
                          ΜΕΤROPOLITAN <br />
                          GENERAL
                        </th>
                        <th class="text-center noBorderTop noBorderRight">
                          CRETA <br />
                          INTERCLINIC
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in urgentBlue"
                        :key="item.id"
                        :class="item.id > 8 ? 'colorBlueBackground' : ''"
                      >
                        <td
                          :id="item.id > 11 ? 'noBorderBottom' : ''"
                          class="noBorderLeft"
                        >
                          {{ item.name }}
                        </td>
                        <td :id="item.id > 11 ? 'noBorderBottom' : ''">
                          <center>
                            <v-icon v-if="item.ugeia"> fas fa-check </v-icon>
                            <span v-if="typeof item.ugeia === 'string'">{{ item.ugeia }}</span>
                          </center>
                        </td>
                        <td :id="item.id > 11 ? 'noBorderBottom' : ''">
                          <center>
                            <p v-if="item.id == 6 || item.id == 7">
                              <v-icon v-if="item.metropolitanHospital">
                                fas fa-check
                              </v-icon>
                              <span style="font-size: 12px">(9.00-21.00)</span>
                            </p>
                            <p v-else>
                              <v-icon v-if="item.metropolitanHospital">
                                fas fa-check
                              </v-icon>
                            </p>
                          </center>
                        </td>
                        <td :id="item.id > 11 ? 'noBorderBottom' : ''">
                          <center>
                            <v-icon v-if="item.mitera"> fas fa-check </v-icon>
                          </center>
                        </td>
                        <td :id="item.id > 11 ? 'noBorderBottom' : ''">
                          <center>
                            <p v-if="item.id == 6">
                              <v-icon v-if="item.metropolitanGeneral">
                                fas fa-check
                              </v-icon>
                              <span style="font-size: 12px">(9.00-21.00)</span>
                            </p>
                            <p v-else>
                              <v-icon v-if="item.metropolitanGeneral">
                                fas fa-check
                              </v-icon>
                            </p>
                          </center>
                        </td>
                        <td
                          :id="item.id > 11 ? 'noBorderBottom' : ''"
                          class="noBorderRight"
                        >
                          <center>
                            <v-icon v-if="item.cretaInterClinic">
                              fas fa-check
                            </v-icon>
                          </center>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
        
          <v-row class="mb--50">
            <v-col cols="12" class="mt--5">
              <h3 class="heading-title colorBlue">ΝΟΣΗΛΕΙΑ</h3>
              <div style="font-size: 18px">
                Έκπτωση 10%. Η έκπτωση γίνεται επί του ιδιωτικού τιμοκαταλόγου. Της έκπτωσης εξαιρούνται υλικά, ειδικά υλικά, φάρμακα, αμοιβές ιατρών.
              </div>
              <hr class="new3 mt--30 mt_sm-20" />
            </v-col>
          </v-row>

          <v-row class="mt--10 mt_sm--10">
            <v-col>
              <v-img
                max-height="60"
                max-width="60"
                class="mb--20"
                style="
                  position: absolute;
                  margin-top: -421px;
                  margin-left: -63px;
                "
                src="../../assets/images/pronomia/icon_kids1.png"
              ></v-img>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col class="mt--60 goToRight d-none d-sm-block" lg="3" md="3" cols="12">
        <div>
          <slot name="thum-img"></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      blue: [
        {
          id: 1,
          name: "Αγγειοχειρουργός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 2,
          name: "Αιματολόγος",
          ugeia: true,
          metropolitanHospital: false,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          id: 3,
          name: "Γαστρεντερολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 18,
          name: "Γναθοχειρουργός",
          ugeia: true,
          metropolitanHospital: false,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          id: 4,
          name: "Γυναικολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          id: 17,
          name: "Διαβητολόγος",
          ugeia: false,
          metropolitanHospital: false,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 5,
          name: "Θωρακοχειρουργός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 6,
          name: "Καρδιολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 19,
          name: "Λοιμωξιολόγος",
          ugeia: true,
          metropolitanHospital: false,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          id: 7,
          name: "Νευρολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 8,
          name: "Νευροχειρουργός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 9,
          name: "Ορθοπαιδικός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 10,
          name: "Ουρολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 11,
          name: "Παθολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 20,
          name: "Παιδίατρος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          id: 21,
          name: "Παιδοχειρουργός",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          id: 12,
          name: "Πλαστικός χειρουργός",
          ugeia: false,
          metropolitanHospital: true,
          mitera: false,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          id: 13,
          name: "Πνευμονολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 14,
          name: "Φυσίατρος",
          ugeia: true,
          metropolitanHospital: false,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          id: 15,
          name: "Χειρουργός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 16,
          name: "ΩΡΛ",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
      ],
      extraBlue: [
        {
          id: 4,
          name: "Αλλεργιολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 1,
          name: "Δερματολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 2,
          name: "Ενδοκρινολόγος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 6,
          name: "Ηπατολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          id: 3,
          name: "Οφθαλμίατρος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          id: 5,
          name: "Ρευματολόγος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          id: 7,
          name: "Ψυχολόγος",
          ugeia: false,
          metropolitanHospital: false,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          id: 8,
          name: "Ψυχίατρος",
          ugeia: false,
          metropolitanHospital: false,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
      ],
      urgentBlue: [
        {
          id: 8,
          name: "Γυναικολόγος",
          ugeia: false,
          metropolitanHospital: false,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          id: 1,
          name: "Καρδιολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 5,
          name: "Νευρολόγος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 2,
          name: "Ορθοπαιδικός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 3,
          name: "Παθολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 9,
          name: "Παιδίατρος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          id: 10,
          name: "Παιδοορθοπαιδικός",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          id: 11,
          name: "Παιδοχειρουργός",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          id: 6,
          name: "Πνευμονολόγος",
          ugeia: false,
          metropolitanHospital: '(9:00-21:00)',
          mitera: false,
          metropolitanGeneral: '(9:00-21:00)',
          cretaInterClinic: true,
        },
        {
          id: 4,
          name: "Χειρουργός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          id: 7,
          name: "ΩΡΛ",
          ugeia: false,
          metropolitanHospital: '(9:00-21:00)',
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        // {
        //   id: 12,
        //   name: "ΩΡΛ",
        //   ugeia: false,
        //   metropolitanHospital: true,
        //   mitera: true,
        //   metropolitanGeneral: false,
        //   cretaInterClinic: false,
        // },
      ],
    };
  },
};
</script>
<style scoped>
.colorBlue {
  color: #1c4081;
  font-family: PFBagueSansPro-Bold;
  font-weight: bold;
}
th {
  font-size: 14px !important;
}
.colorBlueBackground {
  background-color: rgb(238 245 248);
}

.colorWhite {
  background-color: white;
}

h3 {
  font-family: "PFBagueSansPro-Bold";
}
.v-application p,
body p {
  line-height: 1.5;
}
.v-application ul,
.v-application ol,
body ul,
body ol {
  line-height: 25px;
}
@media only screen and (max-width: 600px) {
  .heading-title-new {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 959px) {
  .goToRight {
    margin-left: 50%;
  }
}
</style>
