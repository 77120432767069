<template>
  <v-container style="margin-top: -100px">
    <!-- <v-row align="center" class="row--35"> -->
    <v-row class="row--35">
      <v-col lg="9" md="9" cols="12">
        <div class="about-inner inner">
          <!-- <v-row class="mt--50">
            <v-col lg="9" md="12" sm="12" cols="12">
              <v-row>
                <v-col lg="6" md="6" sm="12" cols="12">
                  <h3 class="heading-title colorBlue">ΝΟΣΗΛΕΙΑ</h3>
                  <p
                    style="
                      font-size: 24px;
                      margin-top: -10px;
                      margin-bottom: 5px;
                    "
                    class="regularStyle"
                  >
                    Έκπτωση 10% στη νοσηλεία
                  </p>
                  <p style="color: #1d1d24; font-size: 16px">
                    Η έκπτωση γίνεται επί του ιδιωτικού τιμοκαταλόγου
                    (εξαιρούνται υλικά, ειδικά υλικά, φάρμακα, αμοιβές ιατρών).
                  </p>
                  <div class="d-sm-block d-md-none">
                    <hr class="new3 mt--30 mt_sm-20" />
                  </div>
                </v-col>

                <v-col
                  lg="6"
                  md="6"
                  sm="12"
                  cols="12"
                  class="mt_sm--20 mt_md--20"
                >
                  <h3 class="heading-title colorBlue">ΜΕΤΑΦΟΡΑ</h3>
                  <p
                    style="
                      font-size: 24px;
                      margin-top: -10px;
                      margin-bottom: 5px;
                    "
                    class="regularStyle"
                  >
                    Δωρεάν μεταφορά
                  </p>
                  <ul>
                    <li style="color: #1d1d24">15% στις φυσικοθεραπείες</li>
                    <li style="color: #1d1d24">
                      10% στις οδοντιατρικές υπηρεσίες (Υγεία και Metropolitan
                      Hospital)
                    </li>
                    <li style="color: #1d1d24">
                      40% στις οφθαλμολογικές εξετάσεις (βυθοσκόπηση, οπτικά
                      πεδία, οπτική οξύτητα)
                    </li>
                    <li style="color: #1d1d24">
                      20% έκπτωση στις λοιπές παιδιατρικές ειδικότητες (Παίδων
                      Μητέρα και Μetropolitan Ηospital)
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
          </v-row> -->
          <!-- <hr class="new3 mt--30 mt_sm-20" /> -->

          <v-row>
            <v-col cols="12">
              <h3 class="heading-title colorBlue">
                ΜΕΤΑΦΟΡΑ
              </h3>
              <h4 class="heading-title">
                Δωρεάν μεταφορά με ασθενοφόρο
              </h4>
              <li class="mt--5 ml-4" style="line-height: 1.5">
                σε περίπτωση νοσηλείας στα Θεραπευτήρια του HHG (εντός Αττικής)
              </li>
              <li class="mt--5 ml-4" style="line-height: 1.5">
                σε περίπτωση νοσηλείας όταν η μεταφορά γίνεται από τα
                Διαγνωστικά Κέντρα HealthSpot σε Θεραπευτήριο του HHG (εντός
                Αττικής)
              </li>
            </v-col>
          </v-row>
          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mb--30">
            <v-col cols="12">
              <div class="p-6">
                <h3 class="heading-title colorBlue mt_sm--0">
                  Δωρεάν συνταγογράφηση διαγνωστικών εξετάσεων
                </h3>
              </div>
              <p>
                Δωρεάν συνταγογράφηση διαγνωστικών εξετάσεων όταν
                πραγματοποιούνται στα διαγνωστικά κέντρα HealthSpot
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <div class="d-sm-flex">
                    <v-img
                      max-height="60"
                      max-width="60"
                      style="margin-right: 12px"
                      class="mb--10"
                      src="../../assets/images/pronomia/pregnancy1.png"
                    ></v-img>
                  </div>
                  <div class="d-sm-flex flex-column">
                    <div class="p-6">
                      <h3 class="heading-title colorBlue mt_sm--0">
                        ΕΓΚΥΜΟΣΥΝΗ & ΤΟΚΕΤΟΣ
                      </h3>
                    </div>
                    <div class="p-6 pb-5" style="margin-top: -14px">
                      <p
                        style="font-size: 20px; color: #333333"
                        class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                      >
                        (Μαιευτική Γυναικολογική Κλινική ΜΗΤΕΡΑ)
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <p>
                Οι μέλλουσες μητέρες έχουν τη δυνατότητα να επωφεληθούν των
                ολοκληρωμένων προνομιακών πακέτων προγεννητικού ελέγχου και
                τοκετού, φυσιολογικού ή με καισαρική τομ
              </p>
              <p>
                Ο προγεννητικός έλεγχος που περιλαμβάνει όλες τις αναγκαίες
                αιματολογικές, αλλά και τις εξειδικευμένες εξετάσεις που
                πραγματοποιούνται κατά τη διάρκεια της εγκυμοσύνης, καθώς και ο
                τοκετός, ανάλογα με την επιλογή του δωματίου, παρέχονται για
                εσάς, τους κατόχους της Health-e bonus card σε προνομιακή τελική
                τιμή και συγκεκριμένα σε:
              </p>
              <ul>
                <li class="mt--5" style="line-height: 1.5">3κλινο: 2.100 €</li>
                <li class="mt--5" style="line-height: 1.5">2κλινο: 2.650 €</li>
              </ul>
              <div style="margin-top:-11px" class="pb--15">
                *οι τιμές τοκετού είναι προ ΦΠΑ.
              </div>

              <p>
                Επίσης στο πλαίσιο της φροντίδας για τη μητέρα, παρέχονται
                δωρεάν και άλλες υπηρεσίες.
              </p>
              <p>
                Για περισσότερες πληροφορίες μπορείτε να καλέσετε στα τηλέφωνα:
                <b>210 68 69 000 & 210 68 69 274</b>.
              </p>
              <h4 class="heading-title">
                Υγεία IVF Εμβρυιογένεσις
              </h4>
              <li class="mt--5 ml-4" style="line-height: 1.5">
                Προνομιακά πακέτα εξωσωματικής γονιμοποίησης και κατάψυξης
                ωαρίων.
              </li>
            </v-col>
          </v-row>

          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mt--30">
            <v-img
              max-height="60"
              max-width="60"
              style="margin-left: 12px"
              class="mb--10"
              src="../../assets/images/pronomia/exetaseis1.png"
            ></v-img>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <div class="d-sm-flex flex-column">
                    <div class="p-6">
                      <h3 class="heading-title colorBlue mt_sm--0">
                        Διαγνωστικές εξετάσεις
                      </h3>
                    </div>
                    <div class="p-6 pb-5" style="margin-top: -14px">
                      <p
                        style="font-size: 20px; color: #333333"
                        class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                      >
                        (Υγεία, Μetropolitan Hospital, Μητέρα, Metropolitan
                        General, Λητώ, Creta Interclinic)
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <ul>
                <li class="mt--5" style="line-height: 1.5">
                  Μειωμένη τιμολογιακή πολιτική στις διαγνωστικές και
                  εργαστηριακές εξετάσεις στα εξωτερικά ιατρεία και στα τμήματα
                  επειγόντων περιστατικών των Θεραπευτηρίων: Παρέχεται η τιμή
                  κρατικού τιμοκαταλόγου +30 % (ΦΕΚ+30%).
                </li>
                <li class="mt--5" style="line-height: 1.5">
                  Στις Μαγνητικές Τομογραφίες (MRI), Αξονικές Τομογραφίες (CT)
                  και Triplex καρδιάς και σώματος παρέχεται τιμή κρατικού
                  τιμοκαταλόγου (ΦΕΚ).
                </li>
                <li class="mt--5" style="line-height: 1.5">
                  Στις εξετάσεις Μοριακής Βιολογίας καθώς και στις ειδικές
                  Αξονικές και Μαγνητικές Τομογραφίες (π.χ. Αξονική
                  Στεφανιογραφία, Μαγνητική Τομογραφία Καρδιάς, Μαγνητική
                  Μαστών, Φασματοσκοπία κ.λπ.) παρέχεται έκπτωση 20% επί του
                  ιδιωτικού τιμοκαταλόγου.
                </li>
                <!-- <li class="mt--5" style="line-height: 1.5">
                  Για τις εξετάσεις που πραγματοποιούνται με παραπεμπτικό ΕΟΠΥΥ
                  απορροφάται η συμμετοχή στα θεραπευτήρια Metropolitan Hospital
                  & Metropolitan General.
                </li> -->
              </ul>
              <v-col class="d-block d-sm-none" cols="12">
                <img
                  max-height="150"
                  style="margin-left: -18px"
                  src="../../assets/images/pronomia/health.png"
                />
              </v-col>
              <h3
                class="heading-title heading-title-new"
                style="font-size: 20px"
              >
                Διαγνωστικά Κέντρα ΗealthSpot
              </h3>
              <ul>
                <li>
                  Τιμές ΦΕΚ στις αιματολογικές εξετάσεις (χωρίς χρήση
                  παραπεμπτικού ΕΟΠΥΥ).
                </li>
                <li>
                  Μοριακός έλεγχος (PCR) για ανίχνευση του Sars-Cov-2 (Covid-19)
                  για τον κάτοχο και τους συγγενείς πρώτου βαθμού: 40€
                </li>
                <li>
                  Rapid test για ανίχνευση του Sars-Cov-2 (Covid-19) για τον
                  κάτοχο και τους συγγενείς πρώτου βαθμού: 8€
                </li>
                <li class="mt--5" style="line-height: 1.5">
                  10% έκπτωση επί του ιδιωτικού τιμοκαταλόγου στις ακτινογραφίες
                  (χωρίς χρήση παραπεμπτικού ΕΟΠΥΥ).
                </li>
                <li class="mt--5" style="line-height: 1.5">
                  10% έκπτωση επί του ιδιωτικού τιμοκαταλόγου στους υπερήχους
                  (χωρίς χρήση παραπεμπτικού ΕΟΠΥΥ).
                </li>
                <!-- <li class="mt--5" style="line-height: 1.5">
                  10% έκπτωση επί του ιδιωτικού τιμοκαταλόγου στους υπερήχους
                  (χωρίς χρήση παραπεμπτικού ΕΟΠΥΥ)
                </li> -->
              </ul>
              <h3
                class="heading-title heading-title-new"
                style="font-size: 20px"
              >
                ΣΥΝΤΑΓΟΓΡΑΦΗΣΗ
              </h3>
              <div>
                <p>
                  Η συνταγογράφηση διαγνωστικών εξετάσεων γίνεται δωρεάν όταν
                  αυτές πραγματοποιούνται στα Διαγνωστικά Κέντρα HealthSpot.
                </p>
              </div>
            </v-col>
          </v-row>
          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mt--20 .d-block .d-sm-none">
            <v-img
              max-height="60"
              max-width="60"
              style="margin-left: 12px"
              class="mb--10"
              src="../../assets/images/pronomia/docare1.png"
            ></v-img>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex flex-column">
                <div class="p-6">
                  <h3 class="heading-title colorBlue">DOCARE ΠΑΡΟΧΕΣ</h3>
                </div>
                <div class="p-6 pb-5" style="margin-top: -14px">
                  <p
                    style="font-size: 20px; color: #333333"
                    class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                  >
                    (Μητέρα)
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-row>
                <v-col lg="12" md="12" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    Υπηρεσίες αδυνατίσματος
                  </h3>
                  <div style="margin-top:-15px" class="pb-5">
                    <span>
                      Πρόγραμμα αδυνατίσματος & σμίλευσης σώματος:<span
                        class="colorBlue"
                      >
                        55€/συνεδρία</span
                      >
                    </span>
                  </div>
                </v-col>
                <v-col lg="12" md="12" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    Υπηρεσίες ομορφιάς
                  </h3>
                  <div style="margin-top:-15px">
                    <p>
                      Πρόγραμμα Laser αποτρίχωση Full Body:
                      <span class="colorBlue">175€/συνεδρία</span>
                    </p>
                  </div>
                  <!-- <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    Βασικός αιματολογικός έλεγχος σε όλα τα θεραπευτήρια και τα
                    διαγνωστικά κέντρα HealthSpot 10 €
                  </h3>
                  <p>
                    (Γενική αίματος, Σάκχαρο, Ουρία, Κρεατινίνη, Χοληστερόλη,
                    HDL, LDL, Τριγλυκερίδια, SGOT, SGPT, TSH, Γενική ούρων).
                  </p> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr class="new3 mt--30 mt_sm-20" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.colorBlue {
  color: rgb(22 71 141);
  font-family: PFBagueSansPro-Bold;
  font-weight: bold;
}
th {
  font-size: 14px !important;
}
.freeTicket {
  background-color: rgb(132, 135, 136);
  position: absolute;
  margin-bottom: 20px;
  left: -23%;
  right: 71%;
  text-align: right;
  color: white;
  font-size: 25px;
  font-weight: bold;
  border-top-right-radius: 25px 22px;
  border-bottom-right-radius: 25px 22px;
  padding-right: 20px;
}

.freeTicketRight {
  background-color: rgb(132, 135, 136);
  position: absolute;
  margin-bottom: 20px;
  left: 0%;
  right: 58%;
  text-align: right;
  color: white;
  font-size: 25px;
  font-weight: bold;
  border-top-right-radius: 25px 22px;
  border-bottom-right-radius: 25px 22px;
  padding-right: 20px;
}

h3 {
  font-family: "PFBagueSansPro-Bold";
  line-height: 1.5;
}

p {
  line-height: 1.5;
}

.v-application p[data-v-5c94bd40],
body p[data-v-5c94bd40] {
  line-height: 1.5;
}

.v-application ul,
.v-application ol,
body ul,
body ol {
  line-height: 1.5;
}
@media only screen and (max-width: 600px) {
  .heading-title-new {
    font-size: 16px !important;
  }
}
</style>
