<template>
  <v-container>
    <!-- <v-row align="center" class="row--35"> -->
    <v-row class="row--35">
      <v-col lg="9" md="9" cols="12">
        <div class="about-inner inner">
          <!-- <div class="section-title"> -->
          <v-row class="mt--50">
            <v-col cols="12">
              <h2 class="heading-title colorGold">ΕΠΙΣΚEΨΕΙΣ</h2>
              <p style="font-size: 35px">
                Προγραμματισμένες επισκέψεις στα εξωτερικά ιατρεία του ομίλου σε
                επιμελητές ιατρούς <span class="colorGold"> • δωρεάνdsad</span>
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Με την επίδειξη της χρυσής σας κάρτας έχετε προσωπική VIP
                εξυπηρέτηση
              </h3>
            </v-col>
          </v-row>
          <v-row class="mt--10 mt_sm--5">
            <v-col cols="12">
              <div class="about-us-list">
                <v-simple-table>
                  <template v-slot:default>
                    <thead style="background-color: rgb(218 192 106)">
                      <tr>
                        <th class="text-left">
                          ΕΙΔΙΚΟΤΗΤΕΣ ΙΑΤΡΩΝ
                        </th>
                        <th class="text-left">
                          ΥΓΕΙΑ
                        </th>
                        <th class="text-left">
                          ΜΕΤROPOLITAN <br />
                          HOSPITAL
                        </th>
                        <th class="text-left">
                          MHTEΡΑ
                        </th>
                        <th class="text-left">
                          ΜΕΤROPOLITAN <br />
                          GENERAL
                        </th>
                        <th class="text-left">
                          CRETA <br />
                          INTERCLINIC
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in gold" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>
                          <v-icon v-if="item.ugeia">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.metropolitanHospital">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.mitera">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.metropolitanGeneral">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.cretaInterClinic">
                            fas fa-check
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p style="font-size: 35px">
                Επιπλέον ειδικότητες με μειωμένη χρέωση
                <span class="colorGold"> • 10 €</span>
              </p>
            </v-col>
          </v-row>
          <v-row class="mt--30 mt_sm--10">
            <v-col cols="12">
              <div class="about-us-list">
                <v-simple-table>
                  <template v-slot:default>
                    <thead style="background-color: rgb(218 192 106)">
                      <tr>
                        <th class="text-left">
                          ΕΙΔΙΚΟΤΗΤΕΣ ΙΑΤΡΩΝ
                        </th>
                        <th class="text-left">
                          ΥΓΕΙΑ
                        </th>
                        <th class="text-left">
                          ΜΕΤROPOLITAN <br />
                          HOSPITAL
                        </th>
                        <th class="text-left">
                          MHTEΡΑ
                        </th>
                        <th class="text-left">
                          ΜΕΤROPOLITAN <br />
                          GENERAL
                        </th>
                        <th class="text-left">
                          CRETA <br />
                          INTERCLINIC
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in extraGold" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>
                          <v-icon v-if="item.ugeia">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.metropolitanHospital">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.mitera">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.metropolitanGeneral">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.cretaInterClinic">
                            fas fa-check
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col cols="12">
              <p style="font-size: 35px">Έξτρα εκπτώσεις</p>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col lg="6" md="6" sm="12" cols="12">
              <p style="font-size: 23px">
                <span style="font-size: 30px" class="colorGold"> 15 %</span>
                στις φυσικοθεραπείες
              </p>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12">
              <p style="font-size: 23px">
                <span style="font-size: 30px" class="colorGold"> 40 %</span>
                έκπτωση στις λοιπές ειδικότητες (Παίδων Μητέρα και Μetropolitan
                Ηospital)
              </p>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12">
              <p style="font-size: 23px">
                <span style="font-size: 30px" class="colorGold"> 10 %</span>
                στις οδοντιατρικές υπηρεσίες
              </p>
              <p style="font-size: 23px">(Υγεία και Metropolitan Hospital)</p>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12">
              <p style="font-size: 23px">
                <span style="font-size: 30px" class="colorGold"> 40 %</span>
                στις οφθαλμολογικές εξετάσεις (βυθοσκόπηση, οπτικά πεδία,
                μέτρηση για γυαλιά)
              </p>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col lg="9" md="9" sm="12" cols="12">
              <h3 class="heading-title colorGold">ΕΠΕΙΓΟΝΤΑ ΠΕΡΙΣΤΑΤΙΚΑ</h3>
              <p style="font-size: 35px">
                Επείγοντα περιστατικά σε εφημερεύοντες ιατρούς των εξωτερικών
                ιατρείων όλο το 24ωρο <span class="colorGold"> • δωρεάν</span>
              </p>
            </v-col>
          </v-row>
          <v-row class="mt--30 mt_sm--10">
            <v-col lg="12" md="12" sm="12" cols="12">
              <div class="about-us-list">
                <v-simple-table>
                  <template v-slot:default>
                    <thead style="background-color: rgb(218 192 106)">
                      <tr>
                        <th class="text-left">
                          ΕΙΔΙΚΟΤΗΤΕΣ ΙΑΤΡΩΝ
                        </th>
                        <th class="text-left">
                          ΥΓΕΙΑ
                        </th>
                        <th class="text-left">
                          ΜΕΤROPOLITAN <br />
                          HOSPITAL
                        </th>
                        <th class="text-left">
                          MHTEΡΑ
                        </th>
                        <th class="text-left">
                          ΜΕΤROPOLITAN <br />
                          GENERAL
                        </th>
                        <th class="text-left">
                          CRETA <br />
                          INTERCLINIC
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in urgentGold" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>
                          <v-icon v-if="item.ugeia">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.metropolitanHospital">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.mitera">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.metropolitanGeneral">
                            fas fa-check
                          </v-icon>
                        </td>
                        <td>
                          <v-icon v-if="item.cretaInterClinic">
                            fas fa-check
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col lg="9" md="9" sm="12" cols="12">
              <h3 class="heading-title colorGold">ΝΟΣΗΛΕΙΑ</h3>
              <h3 class="heading-title">Έκπτωση 15%</h3>
              <p>
                Δυνατότητα πληρωμής σε άτοκες δόσεις. Δωρεάν αναβάθμιση θέσης
                όποτε είναι εφικτό. Η έκπτωση γίνεται επί του ιδιωτικού
                τιμοκαταλόγου. Της έκπτωσης εξαιρούνται υλικά, ειδικά υλικά,
                φάρμακα, αμοιβές ιατρών.
              </p>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col lg="9" md="9" sm="12" cols="12">
              <h3 class="heading-title colorGold">ΜΕΤΑΦΟΡΑ</h3>
              <h3 class="heading-title">Δωρεάν μεταφορά</h3>
              <ul>
                <li>με ασθενοφόρο σε περίπτωση νοσηλείας εντός Αττικής</li>
                <li>
                  δωρεάν διακομιδή εισαγωγής από Healthspot σε Κλινική HHG
                </li>
                <li>
                  30% έκπτωση σε διακομιδή για εξέταση από Healthspot σε Κλινική
                  HHG.
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col lg="9" md="9" sm="12" cols="12">
              <h3 class="heading-title colorGold">ΕΞΤΡΑ ΠΑΡΟΧΕΣ</h3>
              <h3 class="heading-title">Digital Clinic App</h3>
              <p>
                Ψηφιακή υπηρεσία τηλεϊατρικής: Χωρίς αναμονή και χωρίς
                μετακίνηση, η εφαρμογή προσφέρει στον ασθενή ιατρική εκτίμηση,
                διάγνωση και ιατρικές οδηγίες μέσω chat. Με την χρυσή κάρτα
                έχετε δύο δωρεάν συνεδρίες στο Digital Clinic.
              </p>
            </v-col>
          </v-row>

          <v-row class="mt--50">
            <v-col lg="9" md="9" sm="12" cols="12">
              <v-img
                max-height="60"
                max-width="60"
                style="margin-left: 12px;"
                class="mb--20"
                src="../../assets/images/pronomia/pregnancy3.png"
              ></v-img>
              <h3 class="heading-title colorGold">ΕΓΚΥΜΟΣΥΝΗ & ΤΟΚΕΤΌΣ</h3>
              <h3 class="heading-title">
                Πακέτα τοκετού και προγεννητικού ελέγχου
              </h3>
              <p style="font-size: 16px; line-height: 1.5; color: #777978">
                Ενημερωθείτε για τα προνομιακά πακέτα τοκετού και προγεννητικού
                ελέγχου στη Μαιευτική Γυναικολογική Κλινική Μητέρα στα τηλέφωνα:
                210 68 69 000 & 210 68 69 274
              </p>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col cols="12">
              <v-img
                max-height="60"
                max-width="60"
                style="margin-left: 12px;"
                class="mb--20"
                src="../../assets/images/pronomia/exetaseis3.png"
              ></v-img>
              <h3 class="heading-title colorGold">ΔΙΑΓΝΩΣΤΙΚΕΣ ΕΞΕΤΑΣΕΙΣ</h3>
              <ul>
                <li class="mt--10">
                  Ευνοϊκή τιμολογιακή πολιτική στις διαγνωστικές και
                  εργαστηριακές εξετάσεις στα εξωτερικά ιατρεία και στα τμήματα
                  επειγόντων περιστατικών των θεραπευτηρίων: παρέχεται η τιμή
                  κρατικού τιμοκαταλόγου +30 % (ΦΕΚ+30%)
                </li>
                <li class="mt--10">
                  Σε Μαγνητικές Τομογραφίες (MRI), Αξονικές Τομογραφίες (CT) και
                  Triplex Καρδιάς και Σώματος παρέχεται τιμή κρατικού
                  τιμοκαταλόγου (ΦΕΚ)
                </li>
                <li class="mt--10">
                  Στις εξετάσεις Μοριακής Βιολογίας καθώς και σε ειδικές
                  Αξονικές και Μαγνητικές Τομογραφίες (π.χ. Αξονική
                  Στεφανιογραφία, Μαγνητική Τομογραφία Καρδιάς, Μαγνητική
                  Μαστών, Φασματοσκοπία κτλ.) έκπτωση 20% επί του ιδιωτικού
                  τιμοκαταλόγου
                </li>
              </ul>
              <h3 class="heading-title" style="font-size: 20px">
                Διαγνωστικά Κέντρα ΗealthSpot
              </h3>
              <ul>
                <li class="mt--10">
                  Τιμές ΦΕΚ σε αιματολογικές εξετάσεις χωρίς χρήση παραπεμπτικού
                  ΕΟΠΥΥ
                </li>
                <li class="mt--10">
                  Μοριακός έλεγχος για την ανίχνευση του Sars-Cov-2 (Covid-19)-
                  40€
                </li>
                <li class="mt--10">
                  30% έκπτωση επί του ιδιωτικού τιμοκαταλόγου στις ακτινογραφίες
                  (χωρίς χρήση παραπεμπτικού ΕΟΠΥΥ)
                </li>
                <li class="mt--10">
                  20% έκπτωση επί του ιδιωτικού τιμοκαταλόγου στους υπερήχους
                  (χωρίς χρήση παραπεμπτικού ΕΟΠΥΥ)
                </li>
                <li class="mt--10">
                  30% έκπτωση στη συμμετοχή του ασφαλισμένου για χρήση
                  παραπεμπτικού ΕΟΠΥΥ
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col lg="9" md="9" sm="12" cols="12">
              <v-img
                max-height="60"
                max-width="60"
                style="margin-left: 12px;"
                src="../../assets/images/pronomia/docare3.png"
              ></v-img>
              <h3 class="heading-title colorGold">
                DOCARE ΠΑΡΟΧΈΣ
                <span style="color: grey; font-size: 13px">(Μητέρα)</span>
              </h3>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col>
              <v-row>
                <v-col lg="6" md="6" sm="12" cols="12">
                  <h3 class="heading-title" style="font-size: 20px">
                    Υπηρεσίες αδυνατίσματος
                  </h3>
                  <ul>
                    <li>
                      Πρόγραμμα αδυνατίσματος & σμίλευσης σώματος
                      <p class="colorGold">με 55 € η συνεδρία</p>
                    </li>
                    <li>
                      Μείωση & καταπολέμηση της κυτταρίτιδας
                      <p class="colorGold">με 65 € η συνεδρία</p>
                    </li>
                    <li>
                      Πρόγραμμα για τοπικό πάχος & μείωση πόντων
                      <p class="colorGold">με 65 € η συνεδρία</p>
                    </li>
                  </ul>
                  <span style="background-color: grey"> ΔΩΡΕΑΝ </span>
                </v-col>
                <v-col lg="6" md="6" sm="12" cols="12">
                  <h3 class="heading-title" style="font-size: 20px">
                    Υπηρεσίες ομορφιάς
                  </h3>
                  <ul>
                    <li>
                      Μεσοθεραπεία προσώπου
                      <span class="colorGold">με 80 €</span>
                    </li>
                    <li>
                      Πρόγραμμα 3 μεσοθεραπειών προσώπου & peeling
                      <span class="colorGold">με 300 €</span>
                    </li>
                    <li>
                      DOFACECARE: θεραπεία λάμψης, αντιγήρανσης και ενυδάτωσης
                      <span class="colorGold">με 55 € η συνεδρία</span>
                    </li>
                    <li>
                      Πρόγραμμα Laser αποτρίχωσης Full Body
                      <span class="colorGold">με 175 €</span>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col cols="12">
              <v-row class="mt--20">
                <v-col lg="6" md="6" sm="12" cols="12">
                  <h3 class="heading-title">
                    Διαιτολογική παρακολούθηση με πλήρες πρόγραμμα:
                  </h3>
                  <ul>
                    <li>Λιπομέτρηση</li>
                    <li>
                      Σωματομετρήσεις & εξατομικευμένο πρόγραμμα διατροφής καθ’
                      όλη τη διάρκεια των συνεδριών.
                    </li>
                  </ul>
                </v-col>
                <v-col>
                  <h3 class="heading-title">Μία θεραπεία ματιών Eye Lift</h3>
                  <p>
                    H θεραπεία διαρκεί μία ώρα και μειώνει τις λεπτές ρυτίδες
                    γύρω από τα μάτια, αποσυμφορεί τα πρησμένα μάτια και σβήνει
                    τους μαύρους κύκλους.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col lg="3" md="3" sm="12" cols="12" class="mt--20">
              <h3 class="heading-title colorGold">CHECK UP</h3>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12">
              <h3 class="heading-title colorGold">
                <span style="color: grey; font-size: 14px"
                  >(Υγεία, Μetropolitan Hospital, Μητέρα, Metropolitan General,
                  Λητώ, Creta Interclinic)</span
                >
              </h3>
            </v-col>
          </v-row>
          <v-row class="mt--50">
            <v-col cols="12">
              <v-row class="mt--20">
                <v-col lg="4" md="4" sm="12" cols="12">
                  <h3 class="heading-title">Γυναικολογικός έλεγχος</h3>
                  <ul>
                    <li>Λιπομέτρηση</li>
                    <li>
                      Σωματομετρήσεις & εξατομικευμένο πρόγραμμα διατροφής καθ’
                      όλη τη διάρκεια των συνεδριών.
                    </li>
                  </ul>
                  <h5 class="heading-title">Γυναικολογικός έλεγχος</h5>
                  <h5 class="heading-title">Υπερηχογράφημα • δωρεάν</h5>
                  <h5 class="heading-title">Υπέρηχος μαστού • δωρεάν</h5>
                  <h5 class="heading-title">
                    Μαστογραφία • δωρεάν
                    <p>(ανά έτος)</p>
                  </h5>
                  <h5 class="heading-title">
                    Τομοσύνθεση • 40 €<span
                      >(Μetropolitan Hospital, & Metropolitan General)</span
                    >
                  </h5>
                  <h5 class="heading-title">Υπηρεσίες ομορφιάς</h5>
                  <ul>
                    <li>50 €</li>
                    <li>
                      Αιματολογικός έλεγχος (Ασβέστιο, Αλβουμίνη, 25
                      hydroxyvitamin D)
                    </li>
                    <li>
                      Μέτρηση οστικής πυκνότητας ισχίου και οσφυϊκής μοίρας
                    </li>
                    <li>Αξιολόγηση από Ρευματολόγο</li>
                  </ul>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <h3 class="heading-title">Πρόγραμμα ελέγχου προστάτη</h3>
                  <ul>
                    <li>P.S.A.</li>
                    <li>FREE P.S.A.</li>
                    <li>Κλινική εξέταση προστάτη</li>
                  </ul>
                  <h3 class="heading-title">Τριχόπτωση</h3>
                  <ul>
                    <li>
                      Συνεδρία με δερματολόγο και τριχοριζόγραμμα (Metropolitan
                      Hospital & Υγεία) • δωρεάν
                    </li>
                  </ul>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <h3 class="heading-title">Προληπτικός έλεγχος υγείας</h3>
                  <ul>
                    <li>Γενική αίματος</li>
                    <li>Σάκχαρο</li>
                    <li>Κρεατινίνη • ουρία</li>
                    <li>Τριγλυκερίδια</li>
                    <li>FT4</li>
                    <li>HDL χοληστερίνης</li>
                    <li>LDL χοληστερίνης</li>
                    <li>Χοληστερίνη αίματος</li>
                    <li>Φερριτίνη • σίδηρος</li>
                    <li>Τ.Κ.Ε.</li>
                    <li>Παιδιατρική εξέταση</li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col class="mt--100" lg="3" md="3" cols="12">
        <div>
          <slot name="thum-img"></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      gold: [
        {
          name: "Αιματολόγος",
          ugeia: true,
          metropolitanHospital: false,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          name: "Αγγειοχειρουργός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Γαστρεντερολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Γυναικολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          name: "Θωρακοχειρουργός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Καρδιολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Νευρολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Νευροχειρουργός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Ορθοπαιδικός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Ουρολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Παθολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Πλαστικός χειρουργός",
          ugeia: false,
          metropolitanHospital: true,
          mitera: false,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          name: "Πνευμονολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Φυσίατρος",
          ugeia: true,
          metropolitanHospital: false,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          name: "Χειρουργός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "ΩΡΛ",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          name: "Διαβητολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Γναθοχειρουργός",
          ugeia: true,
          metropolitanHospital: false,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          name: "Λοιμωξιολόγος",
          ugeia: true,
          metropolitanHospital: false,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          name: "Παιδίατρος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          name: "Παιδοχειρουργός",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
      ],
      extraGold: [
        {
          name: "Δερματολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Ενδοκρινολόγος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Οφθαλμίατρος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          name: "Αλλεργιολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Ρευματολόγος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          name: "Ηπατολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          name: "Ψυχολόγος",
          ugeia: false,
          metropolitanHospital: false,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          name: "Ψυχίατρος",
          ugeia: false,
          metropolitanHospital: false,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
      ],
      urgentGold: [
        {
          name: "Καρδιολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Ορθοπαιδικός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Παθολόγος",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Χειρουργός",
          ugeia: true,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Νευρολόγος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "Πνευμονολόγος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: false,
          metropolitanGeneral: true,
          cretaInterClinic: true,
        },
        {
          name: "ΩΡΛ",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: true,
          cretaInterClinic: false,
        },
        {
          name: "Γυναικολόγος",
          ugeia: false,
          metropolitanHospital: false,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          name: "Παιδίατρος",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          name: "Παιδοορθοπαιδικός",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          name: "Παιδοχειρουργός",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
        {
          name: "ΩΡΛ",
          ugeia: false,
          metropolitanHospital: true,
          mitera: true,
          metropolitanGeneral: false,
          cretaInterClinic: false,
        },
      ],
    };
  },
};
</script>
<style scoped>
.colorGold {
  color: #d0a000;
  font-family: PFBagueSansPro-Bold;
  font-weight: bold;
}
th {
  font-size: 20px !important;
}
</style>
