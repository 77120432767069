<template>
  <v-container>
    <div>
      <v-row>
        <h3>Health_e Bonus White</h3>
      </v-row>
      <v-row>
        <h3>ΕΚΠΤΩΣΕΙΣ</h3>
      </v-row>
      <v-row>
        <h3>20% </h3><p> έκπτωση σε όλες τις υπηρεσίες των HealthSpot</p>
      </v-row>
      <v-row>
        <h3>15% </h3><p> έκπτωση στα Check up</p>
      </v-row>
      <v-row>
        <h3>10% </h3><p> έκπτωση σε όλες τις υπηρεσίες  του ομίλου HHG</p>
      </v-row>
    </div>
    <div>
       <v-row>
        <h3>ΕΠΙΣΚΕΨΕΙΣ</h3>
      </v-row>
      <v-row>
        <p>Προγραμματισμένες επισκέψεις στα εξωτερικά ιατρεία του ομίλου σε επιμελητές ιατρούς • 20 €</p>
      </v-row>
      <v-row>
        <v-col lg="8">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ΕΙΔΙΚΟΤΗΤΕΣ ΙΑΤΡΩΝ
                  </th>
                  <th class="text-left">
                    ΥΓΕΙΑ
                  </th>
                  <th class="text-left">
                    ΜΕΤROPOLITAN HOSPITAL
                  </th>
                  <th class="text-left">
                    MHTEΡΑ
                  </th>
                  <th class="text-left">
                    ΜΕΤROPOLITAN GENERAL
                  </th>
                  <th class="text-left">
                    CRETA INTERCLINIC
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in blue"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td><v-icon v-if="item.ugeia">
                    fas fa-check
                  </v-icon></td>
                  <td><v-icon v-if="item.metropolitanHospital">
                    fas fa-check
                  </v-icon></td>
                  <td><v-icon v-if="item.mitera">
                    fas fa-check
                  </v-icon></td>
                  <td><v-icon v-if="item.metropolitanGeneral">
                    fas fa-check
                  </v-icon></td>
                  <td><v-icon v-if="item.cretaInterClinic">
                    fas fa-check
                  </v-icon></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <p>Επιπλέον ειδικότητες με μειωμένη χρέωση • 30 €</p>
      </v-row>
       <v-row>
        <v-col lg="8">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ΕΙΔΙΚΟΤΗΤΕΣ ΙΑΤΡΩΝ
                  </th>
                  <th class="text-left">
                    ΥΓΕΙΑ
                  </th>
                  <th class="text-left">
                    ΜΕΤROPOLITAN HOSPITAL
                  </th>
                  <th class="text-left">
                    MHTEΡΑ
                  </th>
                  <th class="text-left">
                    ΜΕΤROPOLITAN GENERAL
                  </th>
                  <th class="text-left">
                    CRETA INTERCLINIC
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in blueExtra"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td><v-icon v-if="item.ugeia">
                    fas fa-check
                  </v-icon></td>
                  <td><v-icon v-if="item.metropolitanHospital">
                    fas fa-check
                  </v-icon></td>
                  <td><v-icon v-if="item.mitera">
                    fas fa-check
                  </v-icon></td>
                  <td><v-icon v-if="item.metropolitanGeneral">
                    fas fa-check
                  </v-icon></td>
                  <td><v-icon v-if="item.cretaInterClinic">
                    fas fa-check
                  </v-icon></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <p>Έξτρα εκπτώσεις</p>
      </v-row>
      <v-row>
        <v-col cols="4">
          <h3>15% <span> στις φυσικοθεραπείες</span></h3>
        </v-col>
        <v-col cols="4">
          <h3>40% <span> στις Οφθαλμολογικές εξετάσεις</span></h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <h3>15% <span> στις φυσικοθεραπείες</span></h3>
        </v-col>
        <v-col cols="4">
          <h3>40% <span> στις Οφθαλμολογικές εξετάσεις</span></h3>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col lg="8">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  ΕΙΔΙΚΟΤΗΤΕΣ ΙΑΤΡΩΝ
                </th>
                <th class="text-left">
                  ΥΓΕΙΑ
                </th>
                <th class="text-left">
                  ΜΕΤROPOLITAN HOSPITAL
                </th>
                <th class="text-left">
                  MHTEΡΑ
                </th>
                <th class="text-left">
                  ΜΕΤROPOLITAN GENERAL
                </th>
                <th class="text-left">
                  CRETA INTERCLINIC
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in blue"
                :key="item.name"
              >
                <td>{{ item.name }}</td>
                <td><v-icon v-if="item.ugeia">
                  fas fa-check
                </v-icon></td>
                <td><v-icon v-if="item.metropolitanHospital">
                  fas fa-check
                </v-icon></td>
                <td><v-icon v-if="item.mitera">
                  fas fa-check
                </v-icon></td>
                <td><v-icon v-if="item.metropolitanGeneral">
                  fas fa-check
                </v-icon></td>
                <td><v-icon v-if="item.cretaInterClinic">
                  fas fa-check
                </v-icon></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-col>
      </v-row>

      <v-row>
      <v-col lg="8">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  ΕΙΔΙΚΟΤΗΤΕΣ ΙΑΤΡΩΝ
                </th>
                <th class="text-left">
                  ΥΓΕΙΑ
                </th>
                <th class="text-left">
                  ΜΕΤROPOLITAN HOSPITAL
                </th>
                <th class="text-left">
                  MHTEΡΑ
                </th>
                <th class="text-left">
                  ΜΕΤROPOLITAN GENERAL
                </th>
                <th class="text-left">
                  CRETA INTERCLINIC
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in blue"
                :key="item.name"
              >
                <td>{{ item.name }}</td>
                <td><v-icon v-if="item.ugeia">
                  fas fa-check
                </v-icon></td>
                <td><v-icon v-if="item.metropolitanHospital">
                  fas fa-check
                </v-icon></td>
                <td><v-icon v-if="item.mitera">
                  fas fa-check
                </v-icon></td>
                <td><v-icon v-if="item.metropolitanGeneral">
                  fas fa-check
                </v-icon></td>
                <td><v-icon v-if="item.cretaInterClinic">
                  fas fa-check
                </v-icon></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-col>
      </v-row>
   
    <v-row align="center" class="row--35">
      
      <v-col lg="9" md="9" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <h2 class="heading-title">About</h2>
            <p class="description">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which dont look even slightly
              believable. If you are going to use a passage of Lorem Ipsum,
            </p>
          </div>
          <v-row class="mt--30 mt_sm--10">
            <v-col lg="6" md="6" sm="12" cols="12">
              <div class="about-us-list">
                <h3 class="heading-title">
                  Who we are
                </h3>
                <p>
                  There are many vtions of passages of Lorem Ipsum available,
                  but the majority have suffered.
                </p>
              </div>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12">
              <div class="about-us-list">
                <h3 class="heading-title">
                  Who we are
                </h3>
                <p>
                  There are many vtions of passages of Lorem Ipsum available,
                  but the majority have suffered.
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col lg="3" md="3" cols="12">
        <div class="thumbnail">
          <slot name="thum-img"></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    data () {
      return {
        blue: [
          {
            name: 'Αιματολόγος',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Αγγειοχειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Γαστρεντερολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Γυναικολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Θωρακοχειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Καρδιολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Νευρολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Νευροχειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Ορθοπαιδικός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Ουρολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Παθολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Πλαστικός χειρουργός',
            ugeia: false,
            metropolitanHospital: true,
            mitera: false,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
          {
            name: 'Πνευμονολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Φυσίατρος',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Χειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'ΩΡΛ',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Διαβητολόγος',
            ugeia: false,
            metropolitanHospital: false,
            mitera: false,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Γναθοχειρουργός',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Λοιμωξιολόγος',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
          {
            name: 'Παιδίατρος',
            ugeia: false,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
          {
            name: 'Παιδοχειρουργός',
            ugeia: false,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
        ],
        blueExtra: [
          {
            name: 'Δερματολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Ενδοκρινολόγος',
            ugeia: false,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Οφθαλμίατρος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Αλλεργιολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Ρευματολόγος',
            ugeia: false,
            metropolitanHospital: true,
            mitera: false,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Ηπατολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Ψυχολόγος',
            ugeia: false,
            metropolitanHospital: false,
            mitera: false,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Ψυχίατρος',
            ugeia: false,
            metropolitanHospital: false,
            mitera: false,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
        ],
        silver: [
          {
            name: 'Αιματολόγος',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Αγγειοχειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Γαστρεντερολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Γυναικολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Θωρακοχειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Καρδιολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Νευρολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Νευροχειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Ορθοπαιδικός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Ουρολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Παθολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Πλαστικός χειρουργός',
            ugeia: false,
            metropolitanHospital: true,
            mitera: false,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
          {
            name: 'Πνευμονολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Φυσίατρος',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Χειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'ΩΡΛ',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Διαβητολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Γναθοχειρουργός',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Λοιμωξιολόγος',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
          {
            name: 'Παιδίατρος',
            ugeia: false,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
          {
            name: 'Παιδοχειρουργός',
            ugeia: false,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
        ],
        gold: [
          {
            name: 'Αιματολόγος',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Αγγειοχειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Γαστρεντερολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Γυναικολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Θωρακοχειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Καρδιολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Νευρολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Νευροχειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Ορθοπαιδικός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Ουρολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Παθολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Πλαστικός χειρουργός',
            ugeia: false,
            metropolitanHospital: true,
            mitera: false,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
          {
            name: 'Πνευμονολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Φυσίατρος',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Χειρουργός',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'ΩΡΛ',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Διαβητολόγος',
            ugeia: true,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: true,
          },
          {
            name: 'Γναθοχειρουργός',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: true,
            cretaInterClinic: false,
          },
          {
            name: 'Λοιμωξιολόγος',
            ugeia: true,
            metropolitanHospital: false,
            mitera: true,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
          {
            name: 'Παιδίατρος',
            ugeia: false,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
          {
            name: 'Παιδοχειρουργός',
            ugeia: false,
            metropolitanHospital: true,
            mitera: true,
            metropolitanGeneral: false,
            cretaInterClinic: false,
          },
        ],
      }
    },
  }
</script>