<template>
  <v-container>
    <v-row align="center" class="row--35">
      <v-col lg="9" md="9" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <h3 class="heading-title" style="font-size: 24px">
              Mε την πρώτη κιόλας επίσκεψη σε ένα από τα Θεραπευτήρια ή τα
              Διαγνωστικά Κέντρα HealthSpot παρέχεται:
            </h3>
          </div>
          <v-row class="mt--20 mt_sm--10">
            <v-col lg="12" md="12" sm="12" cols="12">
              <div class="about-us-list">
                <!-- <h3>ΕΚΠΤΩΣΕΙΣ</h3> -->
                <p class="mr--10"></p>
                <h3>
                  20%
                  <span class="paragraphSize">
                    έκπτωση σε όλες τις υπηρεσίες των HealthSpot</span
                  >
                </h3>
                <p class="mr--10"></p>
                <h3>
                  15% <span class="paragraphSize"> έκπτωση στα Check up</span>
                </h3>
                <p class="mr--10"></p>
                <h3>
                  10%
                  <span class="paragraphSize">
                    σε όλες τις υπηρεσίες του ομίλου HHG</span
                  >
                </h3>
                <h3>Αντιστοιχία ευρώ και πόντων</h3>

                <h3 class="paragraphSize">
                  Πρωτοβάθμιες υπηρεσίες: 1 ευρώ = 10 πόντοι
                </h3>
                <h3 class="paragraphSize">
                  Διαγνωστικές εξετάσεις στα HealthSpot: 1 ευρώ = 20 πόντοι
                </h3>
                <h3 class="paragraphSize">
                  Ιδιωτική νοσηλεία/νοσηλεία με χρήση δημόσιου ασφαλιστικού
                  φορέα: 1 ευρώ = 3 πόντοι
                </h3>
                <h3 class="paragraphSize">
                  Νοσηλεία με ιδιωτική ασφάλιση: 1 ευρώ = 1 πόντος
                </h3>
                <h3 class="paragraphSize">
                  ✓ Μέσα από την εφαρμογή Health_e Bonus θα ενημερώνεστε άμεσα
                  για τις καμπάνιες μας για την πρόληψη θεμάτων υγείας σε
                  εξαιρετικά προνομιακές τιμές.
                </h3>
                <h3 class="paragraphSize">
                  ✓ Αν είστε κάτοχος της κάρτας Health_e Bonus οποιασδήποτε
                  βαθμίδας, μπορείς να επισκεφθείτε δωρεάν έναν ειδικό παθολόγο
                  του δικτύου υγείας του HHG (Υγεία, Metropolitan Hospital,
                  Mητέρα, Metropolitan General, Λητώ, Creta Interclinic καθώς
                  και τα διαγνωστικά κέντρα HealthSpot), που θα σχεδιάσει ειδικά
                  για εσάς εξατομικευμένο check up προσαρμοσμένο στις ανάγκες
                  και στο ιστορικό υγείας σας σε ιδιαίτερα προνομιακή τιμή.
                </h3>
                <h3>Φόρτωση πόντων</h3>

                <h3 class="paragraphSize">
                  <p>
                    Οι πόντοι πιστώνονται στο λογαριασμό σας εφόσον και αν έχετε
                    κατεβάσει την εφαρμογή, δίνοντας τον αριθμό του κινητού σας 
                    τηλεφώνου στο ταμείο του θεραπευτηρίου που εξυπηρετηθήκατε.
                  </p>
                  <p>
                    Από τη στιγμή της ενεργοποίησης της health e-bonus card
                    έχετε την δυνατότητα έως και τρεις μήνες αργότερα να
                    ζητήσετε να πιστωθούν οι πόντοι σας για υπηρεσίες που έχετε
                    εξοφλήσει.
                  </p>
                  <p>
                    π.χ
                    <b style="font-weight: 600;">ενεργοποίηση κάρτας:</b>
                    20/3/22 ,
                    <b style="font-weight: 600;">εξέταση</b>
                    : 25/3/22 εάν δεν λάβατε πόντους την ίδια μέρα, μπορείτε να
                    ζητήσετε να σας πιστώσουν τους πόντους σας εντός 3 μηνών από
                    τη στιγμή που πραγματοποιήσατε την εξέταση.
                  </p>
                </h3>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- <v-col lg="3" md="3" cols="12">
        <div class="thumbnail">
          <slot name="thum-img"></slot>
        </div>
      </v-col> -->
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.paragraphSize {
  font-size: 16px;
  font-weight: 500;
  font-family: "PFBagueSansPro-Light";
}

h3 {
  font-family: "PFBagueSansPro-Bold";
}
.v-application p,
body p {
  line-height: 1.5;
}
.v-application ul,
.v-application ol,
body ul,
body ol {
  line-height: 1.5;
}
@media only screen and (max-width: 600px) {
  .heading-title-new {
    font-size: 16px !important;
  }
}
</style>
