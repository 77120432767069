var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VueSlickCarousel',_vm._b({staticClass:"slider-activation rn-slick-dot dot-light"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.sliderContent),function(slider,i){return _c('div',{key:i,staticClass:"\n        slide slide-style-2\n        slider-box-content\n        without-overlay\n        d-flex\n        align-center\n        bg_image\n      ",style:({
        backgroundImage: 'url(' + slider.src + ')',
        position: 'relative',
      }),attrs:{"data-black-overlay":"1"}},[_c('v-row',{staticClass:"d-none d-sm-block getMargin",staticStyle:{"position":"absolute !important","bottom":"20vh !important","left":"6vw !important"}},[_c('v-col',{staticClass:"inner",class:slider.text_position,attrs:{"xl":"9","lg":"9","md":"9","sm":"12","xs":"12","cols":"12"}},[_c('h2',{staticClass:"description"},[_vm._v(_vm._s(slider.title))]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(slider.desc)+" ")])]),_c('v-col',{staticClass:"inner",class:slider.text_position,attrs:{"xl":"6","lg":"6","md":"8","sm":"12","xs":"12","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt_sm--0 pt--30",attrs:{"xl":"3","lg":"3","md":"3","sm":"3","cols":"3"}},[_c('a',[_c('img',{staticClass:"mb--10 imageStyle",style:(_vm.$store.state.selectedTab === 'white'
                      ? { opacity: 1 }
                      : { opacity: 0.5 }),attrs:{"src":require("../../assets/images/home/white.png"),"alt":"Corporate Images"},on:{"click":function($event){_vm.$store.state.selectedTab = 'white'}}})])]),_c('v-col',{staticClass:"pt_sm--0 pt--30",attrs:{"xl":"3","lg":"3","md":"3","sm":"3","cols":"3"}},[_c('a',[_c('img',{staticClass:"mb--50 imageStyle",style:(_vm.$store.state.selectedTab === 'blue'
                      ? { opacity: 1 }
                      : { opacity: 0.5 }),attrs:{"src":require("../../assets/images/home/blue.png"),"alt":"Corporate Images"},on:{"click":function($event){_vm.$store.state.selectedTab = 'blue'}}})])]),_c('v-col',{staticClass:"pt_sm--0 pt--30",attrs:{"xl":"3","lg":"3","md":"3","sm":"3","cols":"3"}},[_c('a',[_c('img',{staticClass:"mb--50 imageStyle",style:(_vm.$store.state.selectedTab === 'silver'
                      ? { opacity: 1 }
                      : { opacity: 0.5 }),attrs:{"src":require("../../assets/images/home/silver.png"),"alt":"Corporate Images"},on:{"click":function($event){_vm.$store.state.selectedTab = 'silver'}}})])]),_c('v-col',{staticClass:"pt_sm--0 pt--30",attrs:{"xl":"3","lg":"3","md":"3","sm":"3","cols":"3"}},[_c('a',[_c('img',{staticClass:"mb--50",style:(_vm.$store.state.selectedTab === 'gold'
                      ? { opacity: 1 }
                      : { opacity: 0.5 }),attrs:{"src":require("../../assets/images/home/gold.png"),"alt":"Corporate Images imageStyle"},on:{"click":function($event){_vm.$store.state.selectedTab = 'gold'}}})])])],1)],1)],1),_c('v-row',{staticClass:"d-block d-sm-none"},[_c('v-col',{staticClass:"inner getMarginSmall",class:slider.text_position,attrs:{"xl":"9","lg":"9","md":"9","sm":"12","xs":"12","cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('h2',{staticClass:"description"},[_vm._v(_vm._s(slider.title))])]),_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(slider.desc)+" ")])])],1)],1),_c('div',[_c('v-col',{staticClass:"inner getMarginSmallSec",class:slider.text_position,attrs:{"xl":"6","lg":"6","md":"8","sm":"12","xs":"12","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt_sm--0 pt--30",attrs:{"sm":"6","cols":"6"}},[_c('a',[_c('img',{staticClass:"mb--10 imageStyle",style:(_vm.$store.state.selectedTab === 'white'
                        ? { opacity: 1 }
                        : { opacity: 0.5 }),attrs:{"src":require("../../assets/images/home/white.png"),"alt":"Corporate Images"},on:{"click":function($event){_vm.$store.state.selectedTab = 'white'}}})])]),_c('v-col',{attrs:{"sm":"6","cols":"6"}},[_c('a',[_c('img',{staticClass:"mb--10 imageStyle",style:(_vm.$store.state.selectedTab === 'blue'
                        ? { opacity: 1 }
                        : { opacity: 0.5 }),attrs:{"src":require("../../assets/images/home/blue.png"),"alt":"Corporate Images"},on:{"click":function($event){_vm.$store.state.selectedTab = 'blue'}}})])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","cols":"6"}},[_c('a',[_c('img',{staticClass:"mb--10 imageStyle",style:(_vm.$store.state.selectedTab === 'silver'
                        ? { opacity: 1 }
                        : { opacity: 0.5 }),attrs:{"src":require("../../assets/images/home/silver.png"),"alt":"Corporate Images"},on:{"click":function($event){_vm.$store.state.selectedTab = 'silver'}}})])]),_c('v-col',{attrs:{"sm":"6","cols":"6"}},[_c('a',[_c('img',{staticClass:"mb--50",style:(_vm.$store.state.selectedTab === 'gold'
                        ? { opacity: 1 }
                        : { opacity: 0.5 }),attrs:{"src":require("../../assets/images/home/gold.png"),"alt":"Corporate Images imageStyle"},on:{"click":function($event){_vm.$store.state.selectedTab = 'gold'}}})])])],1)],1)],1)],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }