<template>
  <v-container>
    <!-- <v-row align="center" class="row--35"> -->
    <v-row class="row--35 mb--100">
      <!-- <v-col class="d-md-none" lg="4" md="4" cols="12">
        <div style="margin-top: -150px;">
          <slot name="thum-img"></slot>
        </div>
      </v-col> -->
      <v-col lg="9" md="9" sm="12" cols="12">
        <div class="about-inner inner">
          <v-row class="mt--60 mt_sm--30">
            <div class="d-sm-flex flex-column">
              <div class="p-6">
                <h3 class="heading-title colorBlue mt_sm--0">
                  CHECK UP
                </h3>
              </div>
              <div class="p-6 pb-5" style="margin-top: -14px">
                <p
                  style="font-size: 20px; color: #333333"
                  class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                >
                  (Υγεία, Μetropolitan Hospital, Μητέρα, Metropolitan General,
                  Λητώ, Creta Interclinic)
                </p>
              </div>
            </div>
          </v-row>
          <v-row>
            <v-col xs="8" sm="8" md="12" lg="12" cols="8">
              <v-row class="mt--20">
                <v-col
                  lg="4"
                  md="4"
                  sm="12"
                  cols="12"
                  class="withoutBorder"
                  style="border-right: 1px dotted"
                >
                  <h3 class="heading-title" style="color: red; font-size: 20px">
                    Check up για τη γυναίκα
                  </h3>
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="font-size: 20px"
                  ></h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Γυναικολογικός έλεγχος: <span class="colorBlue"> 35€ </span>
                  </h3>
                  <ul>
                    <li>
                      Εξετάσεις Αίματος: Γενική αίματος, TSH, Σίδηρος, Φερριτίνη
                    </li>
                    <li>Γενική ούρων</li>
                    <li>Τεστ ΠΑΠ</li>
                    <li>Βασική Γυναικολογική εξέταση</li>
                  </ul>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Υπέρηχος μαστού: <span class="colorBlue"> 25€ </span>
                  </h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Μαστογραφία: <span class="colorBlue"> 40€ </span>
                  </h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--0"
                    style="font-size: 20px"
                  >
                    Τομοσύνθεση: <span class="colorBlue"> 50€ </span>
                    <span
                      style="font-size: 13px; line-height: 1.5; color: #777978"
                      class="mb_sm--20 mb_md--20 mt--5"
                    >
                      (Μetropolitan Hospital & Metropolitan General)
                    </span>
                  </h3>
                </v-col>
                <v-col
                  lg="4"
                  md="4"
                  sm="12"
                  cols="12"
                  class="withoutBorder"
                  style="border-right: 1px dotted"
                >
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="color: red; font-size: 20px"
                  >
                    Check up για τον άνδρα
                  </h3>
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="font-size: 20px"
                  ></h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Πρόγραμμα ελέγχου προστάτη:
                    <span class="colorBlue"> 35€</span>
                  </h3>
                  <ul>
                    <li>P.S.A.</li>
                    <li>FREE P.S.A.</li>
                    <li>Κλινική εξέταση προστάτη</li>
                  </ul>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="color: red; font-size: 20px"
                  >
                    Check up για το παιδί
                    <span
                      style="font-size: 13px; line-height: 1.5; color: #777978"
                      class="mb_sm--20 mb_md--20 mt--5"
                    >
                      (Μetropolitan Hospital, Mητέρα)
                    </span>
                  </h3>
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="font-size: 20px"
                  ></h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Προληπτικός έλεγχος υγείας:
                    <span class="colorBlue"> 55€</span>
                  </h3>
                  <ul>
                    <li>Γενική αίματος</li>
                    <li>Σάκχαρο</li>
                    <li>Κρεατινίνη</li>
                    <li>Ουρία</li>
                    <li>Τριγλυκερίδια</li>
                    <li>FT4</li>
                    <li>HDL χοληστερίνης</li>
                    <li>LDL χοληστερίνης</li>
                    <li>Χοληστερίνη αίματος</li>
                    <li>Φερριτίνη</li>
                    <li>Σίδηρος</li>
                    <li>Τ.Κ.Ε.</li>
                    <li>Παιδιατρική εξέταση</li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt--50 mt_sm--10">
            <v-col lg="9" md="9" sm="12" cols="12">
              <h3
                class="heading-title heading-title-new mt--10 mb--10"
                style="font-size: 20px"
              >
                Βασικός αιματολογικός έλεγχος σε όλα τα θεραπευτήρια και τα
                διαγνωστικά κέντρα Healthspot:
                <span class="colorBlue"> 10€</span>
              </h3>
              <p style="font-size: 16px">
                Περιλαμβάνει: Γενική Αίματος, Σάκχαρο, Ουρία, Κρεατινίνη,
                Χοληστερόλη, HDL, LDL, Τριγλυκερίδια, SGOT, SGPT, TSH, Γενική
                Ούρων.
              </p>
            </v-col>
          </v-row>
          <v-row class="d-block d-sm-none mt--50 mt_sm--10">
            <v-col lg="9" md="9" sm="12" cols="12">
              <h3
                class="heading-title heading-title-new mt--10 mb--10"
                style="font-size: 20px"
              >
                Φτιάξτε το δικό σας check up
              </h3>
              <p style="font-size: 16px">
                Αν είστε κάτοχος της κάρτας Health_e Bonus οποιασδήποτε
                βαθμίδας, μπορείτε να επισκεφθείτε δωρεάν έναν ειδικό παθολόγο
                του δικτύου υγείας του HHG (Υγεία, Metropolitan Hospital,
                Mητέρα, Metropolitan General, Λητώ, Creta Interclinic καθώς και
                τα Διαγνωστικά Κέντρα HealthSpot) που θα σχεδιάσει ειδικά για
                εσάς εξατομικευμένο check up προσαρμοσμένο στις ανάγκες και στο
                ιστορικό υγείας σας, σε ιδιαίτερα προνομιακή τιμή.
              </p>
            </v-col>
          </v-row>
        </div>

        <hr class="new3 mt--30 mt_sm-20" />

        <v-row class="mt--20 .d-block .d-sm-none">
          <v-img
            height="85"
            max-width="110"
            style="margin-left: 12px"
            class="mb--10"
            src="../../assets/images/pronomia/homecare_logo.svg"
          ></v-img>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-column">
              <div class="p-6">
                <h3 class="heading-title colorBlue">HomeCare</h3>
              </div>
              <div class="p-6 pb-5" style="margin-top: -14px">
                <p
                  style="font-size: 20px; color: #333333"
                  class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                >
                  Κατ’ οίκον Υπηρεσίες Υγείας
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <h3 class="heading-title heading-title-new" style="font-size: 20px">
          Εάν έχετε νοσηλευτεί σε ένα από τα θεραπευτήρια του HHG έχετε έκπτωση
          10% στην υπηρεσία HomeCare
        </h3>
        <v-row>
          <v-col>
            <v-row>
              <v-col lg="12" md="12" sm="12" cols="12">
                <h3
                  class="heading-title heading-title-new"
                  style="font-size: 20px"
                >
                  20% Έκπτωση σε
                </h3>
                <div style="margin-top:-15px" class="pb-5">
                  <ul>
                    <li>Αιμοληψία & μεταφορά δείγματος</li>
                    <li>Συλλογή ούρων ή άλλων βιολογικών υγρών</li>
                    <li>
                      Παρακολούθηση του ασθενή & καταγραφή των ζωτικών σημείων
                    </li>
                    <li>Χορήγηση φαρμάκου με υποδόρια ένεση</li>
                    <li>Χορήγηση φαρμάκου με ενδομυική ένεση</li>
                    <li>Χορήγηση εισπνεόμενων φαρμάκων με δοσομετρητή</li>
                    <li>Χορήγηση οξυγόνου με μάσκα</li>
                    <li>Ενδοφλέβια χορήγηση ορού</li>
                    <li>Ενδοφλέβια χορήγηση φαρμάκων</li>
                    <li>Αναρρόφηση τραχειοβρογχικών εκκρίσεων</li>
                    <li>Υποκλυσμός</li>
                    <li>Παρεντερική σίτιση</li>
                    <li>Τοποθέτηση ουροκαθετήρα & πλύσεις ουροδόχου κύστεως</li>
                    <li>Ατομική υγιεινή</li>
                    <li>
                      Φροντίδα στομίων & εκπαίδευση (γαστροστομία, κολοστομία,
                      κά)
                    </li>
                    <li>Ηλεκτροκαρδιογράφημα κατ’ οίκον</li>
                    <li>Αλλαγή & περιποίηση τραύματος</li>
                    <li>Πρόληψη & φροντίδα κατάκλισης</li>
                    <li>Περιποίηση εγκαύματος</li>
                    <li>Εκπαίδευση ασθενούς, κατά περίπτωση</li>
                    <li>Holter ρυθμού (24ωρη καταγραφή ηκγ)</li>
                    <li>Holter πίεσης (24ωρη καταγραφή αρτηριακής πίεσης)</li>
                  </ul>
                </div>
              </v-col>
              <v-col lg="12" md="12" sm="12" cols="12">
                <h3
                  class="heading-title heading-title-new"
                  style="font-size: 20px"
                >
                  10% Έκπτωση σε
                </h3>
                <div style="margin-top:-15px">
                  <ul>
                    <li>Φυσικοθεραπευτική συνεδρία κατ’οικον</li>
                    <li>Ιατρική επίσκεψη κατ’ οίκον</li>
                  </ul>
                </div>
                <!-- <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    Βασικός αιματολογικός έλεγχος σε όλα τα θεραπευτήρια και τα
                    διαγνωστικά κέντρα HealthSpot 10 €
                  </h3>
                  <p>
                    (Γενική αίματος, Σάκχαρο, Ουρία, Κρεατινίνη, Χοληστερόλη,
                    HDL, LDL, Τριγλυκερίδια, SGOT, SGPT, TSH, Γενική ούρων).
                  </p> -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="mt--100 d-none d-md-block" lg="3" md="3" cols="12">
        <div>
          <slot name="thum-img"></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.colorBlue {
  color: rgb(22 71 141);
  font-family: PFBagueSansPro-Bold;
  font-weight: bold;
}

th {
  font-size: 14px !important;
}
.freeTicket {
  background-color: rgb(132, 135, 136);
  position: absolute;
  margin-bottom: 20px;
  left: -23%;
  right: 71%;
  text-align: right;
  color: white;
  font-size: 25px;
  font-weight: bold;
  border-top-right-radius: 25px 22px;
  border-bottom-right-radius: 25px 22px;
  padding-right: 20px;
}

.freeTicketRight {
  background-color: rgb(132, 135, 136);
  position: absolute;
  margin-bottom: 20px;
  left: 0%;
  right: 58%;
  text-align: right;
  color: white;
  font-size: 25px;
  font-weight: bold;
  border-top-right-radius: 25px 22px;
  border-bottom-right-radius: 25px 22px;
  padding-right: 20px;
}

h3 {
  font-family: "PFBagueSansPro-Bold";
}

.v-application p,
body p {
  line-height: 1.5;
}
.v-application ul,
.v-application ol,
body ul,
body ol {
  line-height: 1.5;
}
h3 {
  font-family: "PFBagueSansPro-Bold";
  line-height: 1.5;
}
.v-application p {
  line-height: 1.5;
}
p {
  line-height: 1.5;
}

.v-application p,
body p {
  line-height: 1.5;
}
@media only screen and (max-width: 600px) {
  .heading-title-new {
    font-size: 16px !important;
  }
  .withoutBorder {
    border-right: 0px dotted !important;
  }
}
</style>
