<template>
  <v-container style="margin-top: -100px">
    <!-- <v-row align="center" class="row--35"> -->
    <v-row class="row--35">
      <!-- <v-col class="d-md-none" lg="4" md="4" cols="12">
        <div style="margin-top: -35%;">
          <slot name="thum-img"></slot>
        </div>
      </v-col> -->
      <v-col lg="9" md="9" cols="12">
        <div class="about-inner inner">
          <v-row class="mt--80">
            <v-col sm="12" cols="12">
              <h3 class="heading-title colorSilver">
                ΝΟΣΗΛΕΙΑ
              </h3>

              <ul>
                <li>
                  Έκπτωση 15%
                </li>
                <li>
                  Δυνατότητα πληρωμής σε άτοκες δόσεις
                </li>
                <li>
                  Η έκπτωση γίνεται επί του ιδιωτικού τιμοκαταλόγου
                </li>
                <li>
                  Εξαιρούνται υλικά, ειδικά υλικά, φάρμακα, αμοιβές ιατρών
                </li>
              </ul>

              <!-- <h3 class="heading-title">Έκπτωση 15%</h3> -->
              <!-- <p
                style="font-size: 24px; margin-top: -10px; margin-bottom: 10px"
                class="regularStyle"
              >
                Έκπτωση 15%
              </p>
              <h3
                class="heading-title heading-title-new"
                style="font-size: 20px; line-height: 1.5"
              >
                Δυνατότητα πληρωμής σε άτοκες δόσεις
              </h3>
              <p style="font-size: 16px">
                Η έκπτωση γίνεται επί του ιδιωτικού τιμοκαταλόγου. Της έκπτωσης
                εξαιρούνται υλικά, ειδικά υλικά, φάρμακα, αμοιβές ιατρών.
                Εξαιρούνται των εκπτώσεων άγονες, μοριακές εξετάσεις.
              </p> -->
            </v-col>
          </v-row>
          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mt--50 mt_sm--30">
            <v-col sm="12" cols="12">
              <h3 class="heading-title colorSilver">
                ΜΕΤΑΦΟΡΑ
              </h3>

              <h4 class="heading-title">
                Δωρεάν μεταφορά με ασθενοφόρο
              </h4>
              <!-- <p
                style="font-size: 24px; margin-top: -10px; margin-bottom: 5px"
                class="regularStyle"
              >
                Δωρεάν μεταφορά
              </p> -->
              <ul>
                <li>
                  σε περίπτωση νοσηλείας στα Θεραπευτήρια του HHG (εντός
                  Αττικής).
                </li>
                <li>
                  σε περίπτωση νοσηλείας όταν η μεταφορά γίνεται από τα
                  Διαγνωστικά Κέντρα HealthSpot σε Θεραπευτήριο του HHG (εντός
                  Αττικής).
                </li>
                <li>
                  15% έκπτωση σε περίπτωση μεταφοράς για εξέταση από τα
                  Διαγνωστικά Κέντρα HealthSpot σε Θεραπευτήριο του HHG.
                </li>
              </ul>
            </v-col>
          </v-row>
          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mt--50 mt_sm--20">
            <v-col sm="12" cols="12">
              <h3 class="heading-title colorSilver">DIGITAL CLINIC ΑPP</h3>
              <!-- <p
                style="font-size: 24px; margin-top: -10px; margin-bottom: 5px"
                class="regularStyle"
              >
                Mία επιπλέον δωρεάν συνεδρία
              </p> -->
              <p>
                Mία έξτρα δωρεάν συνεδρία στην ψηφιακή υπηρεσία τηλεϊατρικής
                «Digital Clinic». Xωρίς αναµονή και χωρίς µετακίνηση, η εφαρμογή
                Digital Clinic προσφέρει στο χρήστη ιατρική εκτίµηση, διάγνωση
                και ιατρικές οδηγίες μέσω chat.
              </p>
              <p>
                Περισσότερες πληροφορίες στο https://www.digitalclinic.gr/el/
              </p>
              <!-- <p>
                Ψηφιακή υπηρεσία τηλεϊατρικής: Χωρίς αναμονή και χωρίς
                μετακίνηση, η εφαρμογή προσφέρει στον ασθενή ιατρική εκτίμηση,
                διάγνωση και ιατρικές οδηγίες μέσω chat. Με την ασημένια κάρτα
                έχετε μία δωρεάν συνεδρία στο Digital Clinic.
              </p> -->
            </v-col>
          </v-row>

          <v-row class="mt--50 mt_sm--20">
            <v-col sm="12" cols="12">
              <h4 class="heading-title colorSilver">
                Δωρεάν συνταγογράφηση διαγνωστικών εξετάσεων
              </h4>
              <p>όταν πραγματοποιούνται στα διαγνωστικά κέντρα HealthSpot.</p>
            </v-col>
          </v-row>

          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mt--30">
            <v-img
              max-height="60"
              max-width="60"
              style="margin-left: 12px"
              class="mb--10"
              src="../../assets/images/pronomia/pregnancy2.png"
            ></v-img>
          </v-row>
          <v-row>
            <v-col sm="12" cols="12">
              <div class="d-sm-flex flex-column">
                <div class="p-6">
                  <h3 class="heading-title colorSilver">
                    ΕΓΚΥΜΟΣΥΝΗ & ΤΟΚΕΤΟΣ
                  </h3>
                </div>
                <div class="p-6 pb-5" style="margin-top: -14px">
                  <p
                    style="font-size: 20px; color: #333333"
                    class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                  >
                    (Μαιευτική Γυναικολογική Κλινική ΜΗΤΕΡΑ)
                  </p>
                </div>
              </div>
              <p>
                Οι μέλλουσες μητέρες έχουν τη δυνατότητα να επωφεληθούν των
                ολοκληρωμένων προνομιακών πακέτων προγεννητικού ελέγχου και
                τοκετού, φυσιολογικού ή με καισαρική τομή.
              </p>
              <p>
                Ο προγεννητικός έλεγχος που περιλαμβάνει όλες τις αναγκαίες
                αιματολογικές, αλλά και τις εξειδικευμένες εξετάσεις που
                πραγματοποιούνται κατά τη διάρκεια της εγκυμοσύνης, καθώς και ο
                τοκετός, ανάλογα με την επιλογή του δωματίου, παρέχονται για
                εσάς, τους κατόχους της Health-e bonus card σε προνομιακή τελική
                τιμή και συγκεκριμένα σε:
              </p>
              <ul>
                <li class="mt--5">3κλινο: 2.100 €</li>
                <li class="mt--5">2κλινο: 2.650 €</li>
              </ul>
              <div style="margin-top:-11px" class="pb--15">
                *οι τιμές τοκετού είναι προ ΦΠΑ.
              </div>
              <p>
                Επίσης στο πλαίσιο της φροντίδας για τη μητέρα, παρέχονται
                δωρεάν και άλλες υπηρεσίες. Για περισσότερες πληροφορίες,
                μπορείτε να καλέσετε στα τηλέφωνα
                <b>210 68 69 000 & 210 68 69 274</b>.
              </p>
              <h4 class="heading-title">
                Υγεία IVF Εμβρυιογένεσις
              </h4>
              <li class="mt--5 ml-4" style="line-height: 1.5">
                Προνομιακά πακέτα εξωσωματικής γονιμοποίησης και κατάψυξης
                ωαρίων.
              </li>
              <!-- <p
                style="font-size: 24px; margin-top: -10px; margin-bottom: 5px"
                class="regularStyle"
              >
                Πακέτα τοκετού και προγεννητικού ελέγχου
              </p>
              <p style="font-size: 16px; line-height: 1.5">
                Ενημερωθείτε για τα προνομιακά πακέτα τοκετού και προγεννητικού
                ελέγχου στη Μαιευτική Γυναικολογική Κλινική Μητέρα στα τηλέφωνα:
                210 68 69 000 & 210 68 69 274
              </p> -->
            </v-col>
          </v-row>
          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mt--30">
            <v-img
              max-height="60"
              max-width="60"
              style="margin-left: 12px"
              class="mb--10"
              src="../../assets/images/pronomia/exetaseis2.png"
            ></v-img>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-sm-flex flex-column">
                <div class="p-6">
                  <h3 class="heading-title colorSilver mt_sm--0">
                    ΔΙΑΓΝΩΣΤΙΚΕΣ ΕΞΕΤΑΣΕΙΣ
                  </h3>
                </div>
                <div class="p-6 pb-5" style="margin-top: -14px">
                  <p
                    style="font-size: 20px; color: #333333"
                    class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                  >
                    (Υγεία, Μetropolitan Hospital, Μητέρα, <br />
                    Metropolitan General, Λητώ, Creta Interclinic)
                  </p>
                </div>
              </div>
              <ul>
                <li class="mt--5">
                  Μειωμένη τιμολογιακή πολιτική στις διαγνωστικές και
                  εργαστηριακές εξετάσεις στα εξωτερικά ιατρεία και στα τμήματα
                  επειγόντων περιστατικών των Θεραπευτηρίων: Παρέχεται η τιμή
                  κρατικού τιμοκαταλόγου +30% (ΦΕΚ+30%).
                </li>
                <li class="mt--5">
                  Στις Μαγνητικές Τομογραφίες (MRI), Αξονικές Τομογραφίες (CT)
                  και Triplex καρδιάς και σώματος παρέχεται τιμή κρατικού
                  τιμοκαταλόγου (ΦΕΚ).
                </li>
                <li class="mt--5">
                  Στις εξετάσεις Μοριακής Βιολογίας καθώς και στις ειδικές
                  Αξονικές και Μαγνητικές Τομογραφίες (π.χ. Αξονική
                  Στεφανιογραφία, Μαγνητική Τομογραφία Καρδιάς, Μαγνητική
                  Μαστών, Φασματοσκοπία κ.λπ.) παρέχεται έκπτωση 20% επί του
                  ιδιωτικού τιμοκαταλόγου.
                </li>
                <!-- <li class="mt--5">
                  Για τις εξετάσεις που πραγματοποιούνται με παραπεμπτικό ΕΟΠΥΥ
                  απορροφάται η συμμετοχή στα θεραπευτήρια Metropolitan Hospital
                  & Metropolitan General.
                </li> -->
              </ul>
              <v-col class="d-block d-sm-none" cols="12">
                <img
                  max-height="150"
                  style="margin-left: -18px"
                  src="../../assets/images/pronomia/health.png"
                />
              </v-col>
              <h3
                class="heading-title heading-title-new"
                style="font-size: 20px"
              >
                Διαγνωστικά Κέντρα ΗealthSpot
              </h3>
              <ul>
                <li>
                  Τιμές ΦΕΚ στις αιματολογικές εξετάσεις χωρίς χρήση
                  παραπεμπτικού ΕΟΠΥΥ.
                </li>
                <li class="mt--5">
                  Μοριακός έλεγχος (PCR) για ανίχνευση του Sars-Cov-2 (Covid-19)
                  για τον κάτοχο και τους συγγενείς πρώτου βαθμού: 35€
                </li>
                <li class="mt--5">
                  Rapid test για ανίχνευση του Sars-Cov-2 (Covid-19) για τον
                  κάτοχο και τους συγγενείς πρώτου βαθμού: 8€
                </li>
                <li class="mt--5">
                  20% έκπτωση επί του ιδιωτικού τιμοκαταλόγου στις ακτινογραφίες
                  (χωρίς χρήση παραπεμπτικού ΕΟΠΥΥ).
                </li>
                <li class="mt--5">
                  15% έκπτωση επί του ιδιωτικού τιμοκαταλόγου στους υπερήχους
                  (χωρίς χρήση παραπεμπτικού ΕΟΠΥΥ).
                </li>
                <li class="mt--5">
                  15% έκπτωση στην συμμετοχή του ασφαλισμένου για χρήση
                  παραπεμπτικού ΕΟΠΥΥ.
                </li>
                <!-- <li class="mt--5">
                  15% έκπτωση στην συμμετοχή του ασφαλισμένου για χρήση
                  παραπεμπτικού ΕΟΠΥΥ
                </li> -->
              </ul>
              <h3
                class="heading-title heading-title-new"
                style="font-size: 20px"
              >
                ΣΥΝΤΑΓΟΓΡΑΦΗΣΗ
              </h3>
              <div>
                <p>
                  Η συνταγογράφηση διαγνωστικών εξετάσεων γίνεται δωρεάν όταν
                  αυτές πραγματοποιούνται στα Διαγνωστικά Κέντρα HealthSpot.
                </p>
              </div>
            </v-col>
          </v-row>
          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mt--20">
            <v-img
              max-height="60"
              max-width="60"
              style="margin-left: 12px"
              class="mb--10"
              src="../../assets/images/pronomia/docare2.png"
            ></v-img>
          </v-row>
          <v-row>
            <v-col sm="12" cols="12">
              <div class="d-flex flex-column">
                <div class="p-6">
                  <h3 class="heading-title colorSilver">DOCARE ΠΑΡΟΧΕΣ</h3>
                </div>
                <div class="p-6 pb-5" style="margin-top: -14px">
                  <p
                    style="font-size: 20px; color: #333333"
                    class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                  >
                    (Μητέρα)
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col lg="12" md="12" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    Υπηρεσίες αδυνατίσματος
                  </h3>
                  <ul>
                    <li>
                      Πρόγραμμα αδυνατίσματος & σμίλευσης σώματος:<span
                        class="colorSilver"
                      >
                        55€/συνεδρία</span
                      >
                    </li>
                    <li>
                      Πρόγραμμα για τοπικό πάχος & μείωση πόντων από
                      <span class="colorSilver"> 65€/συνεδρία</span>
                    </li>
                  </ul>
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    Yπηρεσίες ομορφιάς
                  </h3>
                  <ul>
                    <li>
                      Μεσοθεραπεία προσώπου
                      <span class="colorSilver"> 80€</span>
                    </li>
                    <li>
                      Πρόγραμμα Laser αποτρίχωση Full Body
                      <span class="colorSilver">175€/συνεδρία</span>
                    </li>
                  </ul>
                  <!-- <span class="freeTicket mt--20"> ΔΩΡΕΑΝ </span> -->
                </v-col>
              </v-row>

              <v-row class="mt--20">
                <v-col lg="12" md="12" sm="12" cols="12">
                  <span class="freeTicketText"> ΔΩΡΕΑΝ </span>
                </v-col>
              </v-row>
              <v-row class="mt--10">
                <v-col lg="12" md="12" sm="12" cols="12">
                  <p>
                    ✓ Μία θεραπεία ματιών Eye Lift: H θεραπεία διαρκεί μία ώρα
                    και μειώνει τις λεπτές ρυτίδες, αποσυμφορεί τα πρησμένα
                    μάτια και «σβήνει» τους μαύρους κύκλους.
                  </p>
                  <!-- <p>
                    ✓ Ένας αιματολογικός έλεγχος ετησίως σε όλα τα θεραπευτήρια
                    (Γενική αίματος, Σάκχαρο, Ουρία, Κρεατινίνη, Χοληστερόλη,
                    HDL, LDL, Τριγλυκερίδια, SGOT, SGPT, TSH, Γενική ούρων).
                  </p>
                  <p>
                    ✓ Ένας βασικός αιματολογικός έλεγχος ετησίως στα διαγνωστικά
                    κέντρα HealthSpot (Γενική αίματος, Γενική Ούρων, Σάκχαρο,
                    Ουρία, Χοληστερίνη).
                  </p> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mt--60 mt_sm--30">
            <div class="d-sm-flex flex-column">
              <div class="p-6">
                <h3 class="heading-title colorSilver mt_sm--0 ml--15">
                  CHECK UP
                </h3>
              </div>
              <div class="p-6 pb-5" style="margin-top: -14px">
                <p
                  style="font-size: 20px; color: #333333"
                  class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                >
                  (Υγεία, Μetropolitan Hospital, Μητέρα, <br />
                  Metropolitan General, Λητώ, Creta Interclinic)
                </p>
              </div>
            </div>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row class="mt--20 mt_sm--0">
                <v-col
                  lg="4"
                  md="4"
                  sm="12"
                  cols="12"
                  class="withoutBorder"
                  style="border-right: 1px dotted"
                >
                  <h3 class="heading-title" style="color: red; font-size: 20px">
                    Check up για τη γυναίκα
                  </h3>
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="font-size: 20px"
                  ></h3>

                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Γυναικολογικός έλεγχος:
                    <span class="colorSilver"> 30 €</span>
                  </h3>
                  <ul>
                    <li>
                      Εξετάσεις αίματος: Γενική αίματος, TSH, Σίδηρος, Φερριτίνη
                    </li>
                    <li>Γενική ούρων</li>
                    <li>Τεστ ΠΑΠ</li>
                    <li>Βασική γυναικολογική εξέταση</li>
                  </ul>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Ενδοκολπικό Υπερηχογράφημα:
                    <span class="colorSilver"> 20 €</span>
                  </h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Υπέρηχος μαστού: <span class="colorSilver"> 25 €</span>
                  </h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Μαστογραφία: <span class="colorSilver"> 40 €</span>
                  </h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--0"
                    style="font-size: 20px"
                  >
                    Τομοσύνθεση: <span class="colorSilver"> 50 €</span>
                    <span
                      style="font-size: 13px; line-height: 1.5; color: #777978"
                      class="mb_sm--20 mb_md--20 mt--5"
                    >
                      (Μetropolitan Hospital, & Metropolitan General)
                    </span>
                  </h3>
                </v-col>
                <v-col
                  lg="4"
                  md="4"
                  sm="12"
                  cols="12"
                  class="withoutBorder"
                  style="border-right: 1px dotted"
                >
                  <h3 class="heading-title" style="color: red; font-size: 20px">
                    Check up για τον άνδρα
                  </h3>
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="font-size: 20px"
                  ></h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Πρόγραμμα ελέγχου προστάτη:
                    <span class="colorSilver"> 30 €</span>
                  </h3>
                  <ul>
                    <li>PSA</li>
                    <li>FREE PSA</li>
                    <li>Κλινική εξέταση προστάτη</li>
                  </ul>
                </v-col>
                <v-col lg="4" md="4" sm="12" cols="12">
                  <h3 class="heading-title" style="color: red; font-size: 20px">
                    Check up για το παιδί
                    <span
                      style="font-size: 13px; line-height: 1.5; color: #777978"
                      class="mb_sm--20 mb_md--20 mt--5"
                    >
                      (Μetropolitan Hospital, Mητέρα)
                    </span>
                  </h3>
                  <h3
                    class="heading-title mt--10 mb--10"
                    style="font-size: 20px"
                  ></h3>
                  <h3
                    class="heading-title heading-title-new mt--10 mb--10"
                    style="font-size: 20px"
                  >
                    Προληπτικός έλεγχος υγείας:
                    <span class="colorSilver"> 45 €</span>
                  </h3>
                  <ul>
                    <li>Γενική αίματος</li>
                    <li>Σάκχαρο</li>
                    <li>Κρεατινίνη</li>
                    <li>Ουρία</li>
                    <li>Τριγλυκερίδια</li>
                    <li>FT4</li>
                    <li>HDL χοληστερίνης</li>
                    <li>LDL χοληστερίνης</li>
                    <li>Χοληστερίνη αίματος</li>
                    <li>Φερριτίνη</li>
                    <li>Σίδηρος</li>
                    <li>Τ.Κ.Ε.</li>
                    <li>Παιδιατρική εξέταση</li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt--20">
            <v-col lg="12" md="12" sm="12" cols="12">
              <span class="freeTicketText"> ΔΩΡΕΑΝ </span>
            </v-col>
          </v-row>
          <v-row class="mt--10">
            <v-col lg="12" md="12" sm="12" cols="12">
              <p>
                ✓ Ένας αιματολογικός έλεγχος ανά έτος σε όποιο Θεραπευτήριο του
                Ομίλου επιθυμείτε. Περιλαμβάνει: Γενική αίματος, Σάκχαρο, Ουρία,
                Κρεατινίνη, Χοληστερόλη, HDL, LDL, Τριγλυκερίδια, SGOT, SGPT,
                TSH, Γενική ούρων.
              </p>
              <p style="font-size:23px; margin: -15px 0 1px 0"><b>ή</b></p>
              <p>
                ✓ Ένας βασικός αιματολογικός έλεγχος ανά έτος στα Διαγνωστικά
                Κέντρα HealthSpot. Περιλαμβάνει: Γενική αίματος, Γενική Ούρων,
                Σάκχαρο, Ουρία, Χοληστερίνη.
              </p>
            </v-col>
          </v-row>
          <hr class="new3 mt--30 mt_sm-20" />

          <v-row class="mt--20 .d-block .d-sm-none">
            <v-img
              height="85"
              max-width="110"
              style="margin-left: 12px"
              class="mb--10"
              src="../../assets/images/pronomia/homecare_logo.svg"
            ></v-img>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex flex-column">
                <div class="p-6">
                  <h3 class="heading-title colorBlue">HomeCare</h3>
                </div>
                <div class="p-6 pb-5" style="margin-top: -14px">
                  <p
                    style="font-size: 20px; color: #333333"
                    class="mb_sm--20 mb_md--20 ml--10 ml_sm--0"
                  >
                    Κατ’ οίκον Υπηρεσίες Υγείας
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>

          <h3 class="heading-title heading-title-new" style="font-size: 20px">
          Εάν έχετε νοσηλευτεί σε ένα από τα θεραπευτήρια του HHG έχετε έκπτωση
          10% στην υπηρεσία HomeCare
        </h3>
          <v-row>
            <v-col>
              <v-row>
                <v-col lg="12" md="12" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    35% Έκπτωση σε
                  </h3>
                  <div style="margin-top:-15px" class="pb-5">
                    <ul>
                      <li>Αιμοληψία & μεταφορά δείγματος</li>
                      <li>Συλλογή ούρων ή άλλων βιολογικών υγρών</li>
                      <li>Ηλεκτροκαρδιογράφημα κατ’ οίκον</li>
                    </ul>
                  </div>
                </v-col>
                <v-col lg="12" md="12" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    30% Έκπτωση σε
                  </h3>
                  <div style="margin-top:-15px">
                    <ul>
                      <li>
                        Παρακολούθηση του ασθενή & καταγραφή των ζωτικών σημείων
                      </li>
                    </ul>
                  </div>
                </v-col>

                <v-col lg="12" md="12" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    25% Έκπτωση σε
                  </h3>
                  <div style="margin-top:-15px">
                    <ul>
                      <li>Χορήγηση φαρμάκου με υποδόρια ένεση</li>
                      <li>Χορήγηση φαρμάκου με ενδομυική ένεση</li>
                      <li>Χορήγηση εισπνεόμενων φαρμάκων με δοσομετρητή</li>
                      <li>Χορήγηση οξυγόνου με μάσκα</li>
                      <li>Ενδοφλέβια χορήγηση ορού</li>
                      <li>Ενδοφλέβια χορήγηση φαρμάκων</li>
                      <li>Αναρρόφηση τραχειοβρογχικών εκκρίσεων</li>
                      <li>Υποκλυσμός</li>
                      <li>Παρεντερική σίτιση</li>
                      <li>
                        Τοποθέτηση ουροκαθετήρα & πλύσεις ουροδόχου κύστεως
                      </li>
                      <li>Ατομική υγιεινή</li>
                      <li>
                        Φροντίδα στομίων & εκπαίδευση (γαστροστομία, κολοστομία,
                        κά)
                      </li>
                      <li>Αλλαγή & περιποίηση τραύματος</li>
                      <li>Πρόληψη & φροντίδα κατάκλισης</li>
                      <li>Περιποίηση εγκαύματος</li>
                      <li>Εκπαίδευση ασθενούς, κατά περίπτωση</li>
                      <li>Holter ρυθμού (24ωρη καταγραφή ηκγ)</li>
                      <li>Holter πίεσης (24ωρη καταγραφή αρτηριακής πίεσης)</li>
                    </ul>
                  </div>
                </v-col>
                <v-col lg="12" md="12" sm="12" cols="12">
                  <h3
                    class="heading-title heading-title-new"
                    style="font-size: 20px"
                  >
                    15% Έκπτωση σε
                  </h3>
                  <div style="margin-top:-15px">
                    <ul>
                      <li>Φυσικοθεραπευτική συνεδρία κατ’οικον</li>
                      <li>Ιατρική επίσκεψη κατ’ οίκον</li>
                    </ul>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- <span class="freeTicket mt--40"> ΔΩΡΕΑΝ </span> -->
          <!-- <v-row class="mt--20">
            <v-col lg="12" md="12" sm="12" cols="12">
              <span class="freeTicketText"> ΔΩΡΕΑΝ </span>
            </v-col>
          </v-row> -->
          <!-- <v-row class="mb--60 mb_sm--10">
            <v-col lg="12" md="12" sm="12" cols="12">
              <h3
                class="heading-title heading-title-new mt--10 mb--10"
                style="font-size: 20px"
              >
                • Ένας αιματολογικός έλεγχος τον χρόνο σε όλα τα θεραπευτήρια
              </h3>
              <p>
                Περιλαμβάνει: Γενική Αίματος, Σάκχαρο, Ουρία, Κρεατινίνη,
                Χοληστερόλη,HDL, LDL, Τριγλυκερίδια, SGOT, SGPT, TSH, Γενική
                Ούρων.
              </p>
              <h3
                class="heading-title heading-title-new mt--10 mb--10"
                style="font-size: 20px"
              >
                • Ένας Βασικός αιματολογικός έλεγχος τον χρόνο στα διαγνωστικά
                κέντρα Healthspot:
              </h3>
              <p>
                Περιλαμβάνει: Γενική αίματος, Γενική Ούρων, Σάκχαρο, Ουρία,
                Χοληστερίνη.
              </p>
              <h3
                class="heading-title heading-title-new mt--10 mb--10"
                style="font-size: 20px"
              >
                • 10% έκπτωση
              </h3>
              <p>στα ειδικά check up στα διαγνωστικά κέντρα Healthspot.</p>
            </v-col>
          </v-row> -->
          <!-- <v-row class="mb_sm--60 d-block d-sm-none mt--50 mt_sm--10">
            <v-col lg="9" md="9" sm="12" cols="12">
              <h3
                class="heading-title heading-title-new mt--10 mb--10"
                style="font-size: 20px"
              >
                Καμπάνιες
              </h3>
              <p style="font-size: 16px">
                Μέσα απο την εφαρμογή θα ενημερώνεστε άμεσα για τις καμπάνιες
                μας για την πρόληψη υγείας σε εξαιρετικά προνομιακές τιμές.
              </p>
            </v-col>
          </v-row> -->
        </div>
      </v-col>
      <v-col class="mt--100 d-none d-md-block" lg="3" md="3" cols="12">
        <div>
          <slot name="thum-img"></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.colorSilver {
  color: rgb(132, 135, 136);
  font-family: PFBagueSansPro-Bold;
  font-weight: bold;
}
th {
  font-size: 14px !important;
}
.freeTicket {
  background-color: rgb(132, 135, 136);
  position: absolute;
  margin-bottom: 20px;
  left: -23%;
  right: 71%;
  text-align: right;
  color: white;
  font-size: 25px;
  font-weight: bold;
  border-top-right-radius: 25px 22px;
  border-bottom-right-radius: 25px 22px;
  padding-right: 20px;
}

.freeTicketText {
  color: red;
  font-size: 22px;
  font-family: "PFBagueSansPro-Bold";
  font-weight: bold;
}

.freeTicketRight {
  background-color: rgb(132, 135, 136);
  position: absolute;
  margin-bottom: 20px;
  left: 0%;
  right: 58%;
  text-align: right;
  color: white;
  font-size: 25px;
  font-weight: bold;
  border-top-right-radius: 25px 22px;
  border-bottom-right-radius: 25px 22px;
  padding-right: 20px;
}
h3 {
  font-family: "PFBagueSansPro-Bold";
}
.v-application p,
body p {
  line-height: 1.5;
}
.v-application ul,
.v-application ol,
body ul,
body ol {
  line-height: 1.5;
}
.newLinwHeight {
  line-height: 1.2 !important ;
}
@media only screen and (max-width: 600px) {
  .heading-title-new {
    font-size: 16px !important;
  }
  .withoutBorder {
    border-right: 0px dotted !important;
  }
}
</style>
